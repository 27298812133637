/*
  Template Name: La Notte - Nail Salon HTML5 Template
  Theme URL: https://themeforest.net/user/dsathemes
  Description: La Notte - Nail Salon HTML5 Template
  Author: DSAThemes
  Author URL: https://themeforest.net/user/dsathemes
  Version: 1.0.0
  Website: www.dsathemes.com
  Tags: DSAThemes, Beauty, Salon, Beauty Parlour, Nails, Manicure, Pedicure, Care, Health, Massage
*/

/* ==========================================================================
  01. GENERAL & BASIC STYLES
  =========================================================================== */

html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

body {
  font-family: "Catamaran", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6c757d;
  font-weight: 400;
}

.container {
  position: relative;
  z-index: 3;
}

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page {
  overflow: hidden;
}

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.wide-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.wide-90 {
  padding-top: 100px;
  padding-bottom: 90px;
}
.wide-80 {
  padding-top: 100px;
  padding-bottom: 80px;
}
.wide-70 {
  padding-top: 100px;
  padding-bottom: 70px;
}
.wide-60 {
  padding-top: 100px;
  padding-bottom: 60px;
}
.wide-50 {
  padding-top: 100px;
  padding-bottom: 50px;
}
.wide-40 {
  padding-top: 100px;
  padding-bottom: 40px;
}
.wide-30 {
  padding-top: 100px;
  padding-bottom: 30px;
}
.wide-20 {
  padding-top: 100px;
  padding-bottom: 20px;
}

/*------------------------------------------*/
/*  Margin Top
/*------------------------------------------*/

.mt-100 {
  margin-top: 100px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-0 {
  margin-top: 0;
}

/*------------------------------------------*/
/*  Margin Top Inverse
/*------------------------------------------*/

.mt-inverse-150 {
  margin-top: -150px;
}
.mt-inverse-140 {
  margin-top: -140px;
}
.mt-inverse-130 {
  margin-top: -130px;
}
.mt-inverse-120 {
  margin-top: -120px;
}
.mt-inverse-110 {
  margin-top: -110px;
}
.mt-inverse-100 {
  margin-top: -100px;
}
.mt-inverse-90 {
  margin-top: -90px;
}
.mt-inverse-80 {
  margin-top: -80px;
}
.mt-inverse-70 {
  margin-top: -70px;
}
.mt-inverse-60 {
  margin-top: -60px;
}
.mt-inverse-50 {
  margin-top: -50px;
}
.mt-inverse-40 {
  margin-top: -40px;
}
.mt-inverse-30 {
  margin-top: -30px;
}
.mt-inverse-20 {
  margin-top: -20px;
}

/*------------------------------------------*/
/*  Margin Bottom
/*------------------------------------------*/

.mb-100 {
  margin-bottom: 100px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-0 {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Margin Left
/*------------------------------------------*/

.ml-100 {
  margin-left: 100px;
}
.ml-95 {
  margin-left: 95px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-85 {
  margin-left: 85px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-75 {
  margin-left: 75px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-55 {
  margin-left: 55px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-5 {
  margin-left: 5px !important;
}

/*------------------------------------------*/
/*  Margin Right
/*------------------------------------------*/

.mr-100 {
  margin-right: 100px;
}
.mr-95 {
  margin-right: 95px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-85 {
  margin-right: 85px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-75 {
  margin-right: 75px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-65 {
  margin-right: 65px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-5 {
  margin-right: 5px !important;
}

/*------------------------------------------*/
/*  Padding Top
/*------------------------------------------*/

.pt-100 {
  padding-top: 100px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-85 {
  padding-top: 85px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-5 {
  padding-top: 5px !important;
}

/*------------------------------------------*/
/*  Padding Bottom
/*------------------------------------------*/

.pb-200 {
  padding-bottom: 200px;
}
.pb-190 {
  padding-bottom: 190px;
}
.pb-180 {
  padding-bottom: 180px;
}
.pb-170 {
  padding-bottom: 170px;
}
.pb-160 {
  padding-bottom: 160px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-95 {
  padding-bottom: 95px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-5 {
  padding-bottom: 5px !important;
}

/*------------------------------------------*/
/*  Padding Left
/*------------------------------------------*/

.pl-100 {
  padding-left: 100px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-85 {
  padding-left: 85px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-55 {
  padding-left: 55px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-5 {
  padding-left: 5px !important;
}

/*------------------------------------------*/
/*  Padding Right
/*------------------------------------------*/

.pr-100 {
  padding-right: 100px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-85 {
  padding-right: 85px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-5 {
  padding-right: 5px !important;
}

/*------------------------------------------*/
/*  Padding Centered
/*------------------------------------------*/

.pc-70 {
  padding-right: 70px;
  padding-left: 70px;
}
.pc-65 {
  padding-right: 65px;
  padding-left: 65px;
}
.pc-60 {
  padding-right: 60px;
  padding-left: 60px;
}
.pc-55 {
  padding-right: 55px;
  padding-left: 55px;
}
.pc-50 {
  padding-right: 50px;
  padding-left: 50px;
}
.pc-45 {
  padding-right: 45px;
  padding-left: 45px;
}
.pc-40 {
  padding-right: 40px;
  padding-left: 40px;
}
.pc-35 {
  padding-right: 35px;
  padding-left: 35px;
}
.pc-30 {
  padding-right: 30px;
  padding-left: 30px;
}
.pc-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.pc-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.pc-15 {
  padding-right: 15px;
  padding-left: 15px;
}
.pc-10 {
  padding-right: 10px;
  padding-left: 10px;
}

/*------------------------------------------*/
/*  BACKGROUND SETTINGS
/*------------------------------------------*/

.rel {
  position: relative !important;
  z-index: 3;
}

.bg-fixed,
.bg-scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg-scroll {
  background-attachment: fixed !important;
}

/*------------------------------------------*/
/*  Background Colors
/*------------------------------------------*/

.bg--white {
  background-color: #fff;
}
.bg--alice-blue {
  background-color: #f0f0f2;
}
.bg--corn-silk {
  background-color: #f8f6f4;
}
.bg--deep-blue {
  background-color: #2d303a;
}
.bg--honey-dew {
  background-color: #f7f5f3;
}
.bg--lavender {
  background-color: #fcf2ff;
}
.bg--mint-cream {
  background-color: #e7eeec;
}
.bg--moccasin {
  background-color: #f5f0ef;
}
.bg--papaya {
  background-color: #fff6f3;
}
.bg--plum {
  background-color: #d07ea7;
}
.bg--rose {
  background-color: #fd1c79;
}
.bg--steel-blue {
  background-color: #c1c9d0;
}
.bg--tiny-pink {
  background-color: #fff1f6;
}
.bg--violet {
  background-color: #912d8e;
}
.bg--violet-red {
  background-color: #da3b85;
}
.bg--white-smoke {
  background-color: #f5f1f2;
}
.bg--wheat {
  background-color: #f1ede5;
}

/*------------------------------------------*/
/*  Background Shapes
/*------------------------------------------*/

.white--shape:after {
  background-color: #ffffff;
}
.dark--shape:after {
  background-color: #ffffff;
}
.alice-blue--shape:after {
  background-color: #f0f0f2;
}
.corn-silk--shape:after {
  background-color: #f8f6f4;
}
.deep-blue--shape:after {
  background-color: #2d303a;
}
.honey-dew--shape:after {
  background-color: #f7f5f3;
}
.lavender--shape:after {
  background-color: #fcf2ff;
}
.mint-cream--shape:after {
  background-color: #e7eeec;
}
.moccasin--shape:after {
  background-color: #f5f0ef;
}
.papaya--shape:after {
  background-color: #fff6f3;
}
.plum--shape:after {
  background-color: #d07ea7;
}
.steel-blue--shape:after {
  background-color: #c1c9d0;
}
.tiny-pink--shape:after {
  background-color: #fff1f6;
}
.white-smoke--shape:after {
  background-color: #f5f1f2;
}
.wheat--shape:after {
  background-color: #f1ede5;
}

/*------------------------------------------*/
/*  Background Image
/*------------------------------------------*/

.bg--tra-img {
  background-image: url(../images/tra-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll !important;
}

/* ==========================================================================
  02. TYPOGRAPHY
  =========================================================================== */

h6,
h5,
h4,
h3,
h2,
h1 {
  color: #373b4d;
  font-family: "Lora", serif;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: -0.015625rem;
}

/* Header H6 */
h6.h6-xs {
  font-size: 1rem;
} /* 16px */
h6.h6-sm {
  font-size: 1.0625rem;
} /* 17px */
h6.h6-md {
  font-size: 1.125rem;
} /* 18px */
h6.h6-lg {
  font-size: 1.1875rem;
} /* 19px */
h6.h6-xl {
  font-size: 1.25rem;
} /* 20px */

/* Header H5 */
h5.h5-xs {
  font-size: 1.3125rem;
} /* 21px */
h5.h5-sm {
  font-size: 1.375rem;
} /* 22px */
h5.h5-md {
  font-size: 1.5rem;
} /* 24px */
h5.h5-lg {
  font-size: 1.625rem;
} /* 26px */
h5.h5-xl {
  font-size: 1.75rem;
} /* 28px */

/* Header H4 */
h4.h4-xs {
  font-size: 1.875rem;
} /* 30px */
h4.h4-sm {
  font-size: 2rem;
} /* 32px */
h4.h4-md {
  font-size: 2.125rem;
} /* 34px */
h4.h4-lg {
  font-size: 2.25rem;
} /* 36px */
h4.h4-xl {
  font-size: 2.375rem;
} /* 38px */

h3.h3-xs {
  font-size: 2.5rem;
} /* 40px */
h3.h3-sm {
  font-size: 2.625rem;
} /* 42px */
h3.h3-md {
  font-size: 2.75rem;
} /* 44px */
h3.h3-lg {
  font-size: 2.875rem;
} /* 46px */
h3.h3-xl {
  font-size: 3rem;
} /* 48px */

/* Header H2 */
h2.h2-xs {
  font-size: 3.125rem;
} /* 50px */
h2.h2-sm {
  font-size: 3.25rem;
} /* 52px */
h2.h2-md {
  font-size: 3.375rem;
} /* 54px */
h2.h2-lg {
  font-size: 3.5rem;
} /* 56px */
h2.h2-xl {
  font-size: 3.75rem;
} /* 60px */

h2.h2-title-xs {
  font-size: 4.0625rem;
} /* 65px */
h2.h2-title-sm {
  font-size: 4.375rem;
} /* 70px */
h2.h2-title-md {
  font-size: 4.6875rem;
} /* 75px */
h2.h2-title-lg {
  font-size: 5rem;
} /* 80px */
h2.h2-title-xl {
  font-size: 5.3125rem;
} /* 85px */

/*------------------------------------------*/
/*  PARAGRAPHS
/*------------------------------------------*/

p.p-sm {
  font-size: 1rem;
} /* 16px */
p {
  font-size: 1.0625rem;
} /* 17px */
p.p-md {
  font-size: 1.125rem;
} /* 18px */
p.p-lg {
  font-size: 1.1875rem;
} /* 19px */
p.p-xl {
  font-size: 1.25rem;
} /* 20px */

p.p-title-xs {
  font-size: 1.3125rem;
} /* 21px */
p.p-title-sm {
  font-size: 1.375rem;
} /* 22px */
p.p-title-md {
  font-size: 1.4375rem;
} /* 23px */
p.p-title-lg {
  font-size: 1.5rem;
} /* 24px */
p.p-title-xl {
  font-size: 1.5625rem;
} /* 25px */

/*------------------------------------------*/
/*  LINK SETTINGS
/*------------------------------------------*/

a {
  color: #6c757d;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

a:hover {
  color: #666;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

/*------------------------------------------*/
/*  TRANSPARENT LINK
/*------------------------------------------*/

p.tra-link {
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.0625rem;
}

p.tra-link a {
  color: #373b4d;
  position: relative;
  padding-bottom: 6px;
  text-decoration: none;
}

p.tra-link a:after {
  content: "";
  background-color: #373b4d;
  height: 2px;
  width: 60%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transition: 0.2s all ease-in;
  -moz-transition: 0.2s all ease-in;
  -o-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
}

p.tra-link a:hover:after {
  width: 60%;
}

p.deep-blue--color.tra-link a,
h5.deep-blue--color.sbox-link a,
.blog-post-txt h5.deep-blue--color a {
  color: #204e5b;
}

p.deep-brown--color.tra-link a,
h5.deep-brown--color.sbox-link a,
.blog-post-txt h5.deep-brown--color a {
  color: #333233;
}

p.deep-blue--color.tra-link a:after,
h5.deep-blue--color.sbox-link a:after {
  background-color: #204e5b;
}

p.deep-brown--color.tra-link a:after,
h5.deep-brown--color.sbox-link a:after {
  background-color: #333233;
}

/*------------------------------------------*/
/*  LISTS
/*------------------------------------------*/

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*------------------------------------------*/
/*  TEXT LIST
/*------------------------------------------*/

.txt-list li i {
  position: relative;
  font-size: 1.05rem;
  line-height: 1.5rem;
  float: left;
  margin-right: 10px;
  top: 2px;
}

.txt-list li p {
  overflow: hidden;
  margin-bottom: 10px;
}

ul.simple-list {
  list-style: disc;
  margin-left: 15px;
}

/*------------------------------------------*/
/*  NUMBER LIST
/*------------------------------------------*/

ol.num-list {
  margin-left: -20px;
}

ol.num-list li p {
  padding-left: 5px;
  margin-bottom: 5px;
}

ol.digit-list {
  padding: 0;
  margin-left: 15px;
}

ol.digit-list p {
  margin-bottom: 8px;
}

/*------------------------------------------*/
/*  BOX LIST
/*------------------------------------------*/

.box-list p {
  position: relative;
  font-weight: 400;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px dashed #ddd;
}

.bg-dark .box-list p {
  border-bottom: 1px dashed #777;
}

.box-list p:last-child {
  position: relative;
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.box-list p span {
  font-weight: 500;
  position: absolute;
  top: 0;
  right: 15px;
}

.bg-dark .box-list p span {
  color: #fff;
}

/*------------------------------------------*/
/*  BUTTON SETTINGS
/*------------------------------------------*/

.btn {
  background-color: transparent;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  padding: 13px 34px 10px;
  border: 1px solid transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Button Size
/*------------------------------------------*/

.btn.btn-sm {
  font-size: 0.85rem;
  padding: 12px 26px 11px;
}

.btn.btn-md {
  font-size: 0.95rem;
  padding: 15px 44px 14px;
}

/*------------------------------------------*/
/*  Button Icon
/*------------------------------------------*/

.btn.ico-15 span {
  position: relative;
  top: 3px;
  right: 1px;
}

/*------------------------------------------*/
/*  Button Color
/*------------------------------------------*/

.white--btn,
.white--hover:hover,
.white--color .white--btn,
.scroll .white--hover:hover {
  color: #373b4d !important;
  background-color: #fff;
  border-color: #fff !important;
}

.tra-white--btn,
.tra-white--hover:hover,
.white--color .tra-white--hover:hover,
.dark-menu .scroll .tra-white--hover:hover {
  color: #fff !important;
  background-color: transparent;
  border-color: #fff !important;
}

.scroll .tra-white--btn,
.scroll .tra-white--hover:hover {
  color: #373b4d !important;
  background-color: transparent;
  border-color: #373b4d !important;
}

.black--btn,
.scroll .black--btn,
.black--hover:hover,
.navbar-dark .black--btn,
.white--color .black--btn,
.scroll .black--hover:hover,
.white--color .black--hover:hover {
  color: #fff !important;
  background-color: #373b4d;
  border-color: #373b4d !important;
}

.tra-black--btn,
.tra-black--hover:hover,
.white--color .tra-black--btn,
.scroll .tra-black--hover:hover,
.white--color .tra-black--hover:hover {
  color: #373b4d !important;
  background-color: transparent;
  border-color: #373b4d !important;
}

.grey--btn,
.white--color .grey--btn {
  color: #666;
  background-color: rgba(17, 36, 70, 0.04);
  border-color: rgba(17, 36, 70, 0.02) !important;
}

.tra-grey--btn,
.white--color .tra-grey--btn {
  color: #373b4d;
  background-color: transparent;
  border-color: #ccc !important;
}

.grey--hover:hover,
.scroll .grey--hover:hover {
  color: #373b4d !important;
  background-color: rgba(17, 36, 70, 0.04);
  border-color: rgba(17, 36, 70, 0.02) !important;
}

.tra-grey--hover:hover,
.scroll .tra-grey--hover:hover {
  color: #373b4d !important;
  background-color: transparent;
  border-color: #ccc !important;
}

.deep-brown--btn,
.scroll .deep-brown--btn,
.white--color .deep-brown--btn,
.deep-brown--hover:hover,
.scroll .deep-brown--hover:hover,
.white--color .deep-brown--hover:hover {
  color: #fff !important;
  background-color: #333233;
  border-color: #333233 !important;
}

.tra-deep-brown--btn,
.scroll .tra-deep-brown--btn,
.white--color .tra-deep-brown--btn,
.tra-deep-brown--hover:hover,
.scroll .tra-deep-brown--hover:hover,
.white--color .tra-deep-brown--hover:hover {
  color: #333233 !important;
  background-color: transparent;
  border-color: #333233 !important;
}

.deep-blue--btn,
.scroll .deep-blue--btn,
.white--color .deep-blue--btn,
.deep-blue--hover:hover,
.scroll .deep-blue--hover:hover,
.white--color .deep-blue--hover:hover {
  color: #fff !important;
  background-color: #204e5b;
  border-color: #204e5b !important;
}

.tra-deep-blue--btn,
.scroll .tra-deep-blue--btn,
.white--color .tra-deep-blue--btn,
.tra-deep-blue--hover:hover,
.scroll .tra-deep-blue--hover:hover,
.white--color .tra-deep-blue--hover:hover {
  color: #204e5b !important;
  background-color: transparent;
  border-color: #204e5b !important;
}

.violet-red--btn,
.scroll .violet-red--btn,
.white--color .violet-red--btn,
.violet-red--hover:hover,
.scroll .violet-red--hover:hover,
.white--color .violet-red--hover:hover {
  color: #fff !important;
  background-color: #da3b85;
  border-color: #da3b85 !important;
}

.tra-violet-red--btn,
.scroll .tra-violet-red--btn,
.white--color .tra-violet-red--btn,
.tra-violet-red--hover:hover,
.scroll .tra-violet-red--hover:hover,
.white--color .tra-violet-red--hover:hover {
  color: #da3b85 !important;
  background-color: transparent;
  border-color: #da3b85 !important;
}

.plum--btn,
.scroll .plum--btn,
.white--color .plum--btn,
.plum--hover:hover,
.scroll .plum--hover:hover,
.white--color .plum--hover:hover {
  color: #fff !important;
  background-color: #d07ea7;
  border-color: #d07ea7 !important;
}

.tra-plum--btn,
.scroll .tra-plum--btn,
.white--color .tra-plum--btn,
.tra-plum--hover:hover,
.scroll .tra-plum--hover:hover,
.white--color .tra-plum--hover:hover {
  color: #d07ea7 !important;
  background-color: transparent;
  border-color: #d07ea7 !important;
}

.red--btn,
.scroll .red--btn,
.white--color .red--btn,
.red--hover:hover,
.scroll .red--hover:hover,
.white--color .red--hover:hover {
  color: #fff !important;
  background-color: #f1292e;
  border-color: #f1292e !important;
}

.tra-red--btn,
.scroll .tra-red--btn,
.white--color .tra-red--btn,
.tra-red--hover:hover,
.scroll .tra-red--hover:hover,
.white--color .tra-red--hover:hover {
  color: #f1292e !important;
  background-color: transparent;
  border-color: #f1292e !important;
}

.rose--btn,
.scroll .rose--btn,
.white--color .rose--btn,
.rose--hover:hover,
.scroll .rose--hover:hover,
.white--color .rose--hover:hover {
  color: #fff !important;
  background-color: #fd1c79;
  border-color: #fd1c79 !important;
}

.tra-rose--btn,
.scroll .tra-rose--btn,
.white--color .tra-rose--btn,
.tra-rose--hover:hover,
.scroll .tra-rose--hover:hover,
.white--color .tra-rose--hover:hover {
  color: #fd1c79 !important;
  background-color: transparent;
  border-color: #fd1c79 !important;
}

/*------------------------------------------*/
/*  Button Focus
/*------------------------------------------*/

.btn:focus {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn.btn-black:focus {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-grey:focus {
  color: #666;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-tra-black:focus,
.btn.btn-tra-grey:focus {
  color: #333;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  VIDEO LINK
/*------------------------------------------*/

.btn-md.btn-video-link {
  color: #666;
  font-size: 18px;
  padding: 10px 8px;
}

.btn-md.btn-video-link span {
  position: relative;
  top: 5px;
  right: 6px;
}

.btn-md.btn-video-link {
  line-height: 30px !important;
}

/*------------------------------------------*/
/*  VIDEO POPUP ICON
/*------------------------------------------*/

.video-preview {
  position: relative;
  text-align: center;
}

.video-btn {
  position: absolute !important;
  top: 50%;
  left: 50%;
  display: inline-block;
  text-align: center;
  color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.video-btn.bg--tra {
  background-color: transparent;
  border: 5px solid #fff;
}

.video-btn-xl {
  width: 120px;
  height: 120px;
  margin-top: -60px;
  margin-left: -60px;
}

.video-btn-lg {
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
}

.video-btn-md {
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
}

.video-btn-sm {
  width: 60px;
  height: 60px;
  margin-top: -30px;
  margin-left: -30px;
}

.video-block-wrapper {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn.video-btn-xl [class^="flaticon-"]:before,
.video-btn.video-btn-xl [class^="flaticon-"]:after {
  line-height: 120px !important;
  margin-left: 10px;
}

.video-btn.bg--travideo-btn-xl [class^="flaticon-"]:before,
.video-btn.bg--travideo-btn-xl [class^="flaticon-"]:after {
  line-height: 90px !important;
  margin-left: 10px;
}

.video-btn.video-btn-lg [class^="flaticon-"]:before,
.video-btn.video-btn-lg [class^="flaticon-"]:after {
  line-height: 100px !important;
  margin-left: 8px;
}

.video-btn.video-btn-md [class^="flaticon-"]:before,
.video-btn.video-btn-md [class^="flaticon-"]:after {
  line-height: 80px !important;
  margin-left: 7px;
}

.video-btn.video-btn-sm [class^="flaticon-"]:before,
.video-btn.video-btn-sm [class^="flaticon-"]:after {
  line-height: 60px !important;
  margin-left: 6px;
}

.video-btn:hover .video-block-wrapper {
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}

.video-btn:before {
  content: "";
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn.video-btn-xl:hover:before {
  opacity: 0.75;
  left: -32px;
  right: -32px;
  top: -32px;
  bottom: -32px;
}

.video-btn.video-btn-lg:hover:before {
  opacity: 0.75;
  left: -25px;
  right: -25px;
  top: -25px;
  bottom: -25px;
}

.video-btn.video-btn-md:hover:before {
  opacity: 0.75;
  left: -18px;
  right: -18px;
  top: -18px;
  bottom: -18px;
}

.video-btn.video-btn-sm:hover:before {
  opacity: 0.75;
  left: -12px;
  right: -12px;
  top: -12px;
  bottom: -12px;
}

/*------------------------------------------*/
/*  VECTOR ICONS
/*------------------------------------------*/

.ico-10 [class*="flaticon-"]:before,
.ico-10 [class*="flaticon-"]:after {
  font-size: 0.75rem;
} /* 15px */
.ico-15 [class*="flaticon-"]:before,
.ico-15 [class*="flaticon-"]:after {
  font-size: 0.9375rem;
} /* 15px */
.ico-20 [class*="flaticon-"]:before,
.ico-20 [class*="flaticon-"]:after {
  font-size: 1.25rem;
} /* 20px */
.ico-25 [class*="flaticon-"]:before,
.ico-25 [class*="flaticon-"]:after {
  font-size: 1.5625rem;
} /* 25px */
.ico-30 [class*="flaticon-"]:before,
.ico-30 [class*="flaticon-"]:after {
  font-size: 1.875rem;
} /* 30px */
.ico-35 [class*="flaticon-"]:before,
.ico-35 [class*="flaticon-"]:after {
  font-size: 2.1875rem;
} /* 35px */
.ico-40 [class*="flaticon-"]:before,
.ico-40 [class*="flaticon-"]:after {
  font-size: 2.5rem;
} /* 40px */
.ico-45 [class*="flaticon-"]:before,
.ico-45 [class*="flaticon-"]:after {
  font-size: 2.8125rem;
} /* 45px */
.ico-50 [class*="flaticon-"]:before,
.ico-50 [class*="flaticon-"]:after {
  font-size: 3.125rem;
} /* 50px */
.ico-55 [class*="flaticon-"]:before,
.ico-55 [class*="flaticon-"]:after {
  font-size: 3.4375rem;
} /* 55px */
.ico-60 [class*="flaticon-"]:before,
.ico-60 [class*="flaticon-"]:after {
  font-size: 3.75rem;
} /* 60px */
.ico-65 [class*="flaticon-"]:before,
.ico-65 [class*="flaticon-"]:after {
  font-size: 4.0625rem;
} /* 65px */
.ico-70 [class*="flaticon-"]:before,
.ico-70 [class*="flaticon-"]:after {
  font-size: 4.375rem;
} /* 70px */
.ico-75 [class*="flaticon-"]:before,
.ico-75 [class*="flaticon-"]:after {
  font-size: 4.6875rem;
} /* 75px */
.ico-80 [class*="flaticon-"]:before,
.ico-80 [class*="flaticon-"]:after {
  font-size: 5rem;
} /* 80px */
.ico-85 [class*="flaticon-"]:before,
.ico-85 [class*="flaticon-"]:after {
  font-size: 5.3125rem;
} /* 85px */
.ico-90 [class*="flaticon-"]:before,
.ico-90 [class*="flaticon-"]:after {
  font-size: 5.625rem;
} /* 90px */
.ico-95 [class*="flaticon-"]:before,
.ico-95 [class*="flaticon-"]:after {
  font-size: 5.9375rem;
} /* 95px */
.ico-100 [class*="flaticon-"]:before,
.ico-100 [class*="flaticon-"]:after {
  font-size: 6.25rem;
} /* 100px */
.ico-105 [class*="flaticon-"]:before,
.ico-105 [class*="flaticon-"]:after {
  font-size: 6.5625rem;
} /* 105px */
.ico-110 [class*="flaticon-"]:before,
.ico-110 [class*="flaticon-"]:after {
  font-size: 6.875rem;
} /* 110px */
.ico-115 [class*="flaticon-"]:before,
.ico-115 [class*="flaticon-"]:after {
  font-size: 7.1875rem;
} /* 115px */
.ico-120 [class*="flaticon-"]:before,
.ico-120 [class*="flaticon-"]:after {
  font-size: 7.5rem;
} /* 120px */
.ico-125 [class*="flaticon-"]:before,
.ico-125 [class*="flaticon-"]:after {
  font-size: 7.8125rem;
} /* 125px */

/*------------------------------------------*/
/*  SECTION ID
/*------------------------------------------*/

.section-id {
  display: block;
  color: #8e8987;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  margin-bottom: 28px;
}

.section-id.section-id-lg {
  font-size: 1rem;
}

.white-color .section-id {
  color: #fff;
}

/*------------------------------------------*/
/*  SECTION TITLE
/*------------------------------------------*/

.title-01,
.title-02 {
  text-align: center;
}

.title-01 h4 {
  line-height: 1;
}

/*------------------------------------------*/
/*  FONT SETTINGS
/*------------------------------------------*/

.txt-300 {
  font-weight: 300;
}
.txt-400 {
  font-weight: 400;
}
.txt-500 {
  font-weight: 500;
}
.txt-600 {
  font-weight: 600;
}
.txt-700 {
  font-weight: 700;
}
.txt-900 {
  font-weight: 900;
}

.txt-upcase {
  display: block;
  font-size: 0.9rem;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.125rem;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  TEXT COLORS
/*------------------------------------------*/

.white--color,
.white--color h2,
.white--color h3,
.white--color h4,
.white--color h5,
.white--color h6,
.white--color p,
.white--color a,
.white--color li,
.white--color i,
.white--color span {
  color: #fff;
}

.white-smoke--color,
.white-smoke--color h2,
.white-smoke--color h3,
.white-smoke--color h4,
.white-smoke--color h5,
.white-smoke--color h6,
.white-smoke--color p,
.white-smoke--color a,
.white-smoke--color li,
.white-smoke--color i,
.white-smoke--color span {
  color: #f2f2f2;
}

.black--color,
.black--color h2,
.black--color h3,
.black--color h4,
.black--color h5,
.black--color h6,
.black--color p,
.black--color a,
.black--color li,
.black--color i,
.black--color span,
.white--color .black--color {
  color: #373b4d;
}

.deep-brown--color,
.deep-brown--color h2,
.deep-brown--color h3,
.deep-brown--color h4,
.deep-brown--color h5,
.deep-brown--color h6,
.deep-brown--color p,
.deep-brown--color a,
.deep-brown--color li,
.deep-brown--color i,
.deep-brown--color span,
.white---color .deepbrown--color {
  color: #333233;
}

.deep-blue--color,
.deep-blue--color h2,
.deep-blue--color h3,
.deep-blue--color h4,
.deep-blue--color h5,
.deep-blue--color h6,
.deep-blue--color p,
.deep-blue--color a,
.deep-blue--color li,
.deep-blue--color i,
.deep-blue--color span,
.white--color .deep-blue--color {
  color: #204e5b;
}

.violet-red--color,
.violet-red--color h2,
.violet-red--color h3,
.violet-red--color h4,
.violet-red--color h5,
.violet-red--color h6,
.violet-red--color p,
.violet-red--color a,
.violet-red--color li,
.violet-red--color i,
.violet-red--color span,
.white--color .violetred--color {
  color: #da3b85;
}

.plum--color,
.plum--color h2,
.plum--color h3,
.plum--color h4,
.plum--color h5,
.plum--color h6,
.plum--color p,
.plum--color a,
.plum--color li,
.plum--color i,
.plum--color span,
.white--color .plum--color {
  color: #d07ea7;
}

.rose--color,
.rose--color h2,
.rose--color h3,
.rose--color h4,
.rose--color h5,
.rose--color h6,
.rose--color p,
.rose--color a,
.rose--color li,
.rose--color i,
.rose--color span,
.white--color .rose--color {
  color: #fd1c79;
}

/*------------------------------------------*/
/*  MORE BUTTON
/*------------------------------------------*/

.more-btn {
  text-align: center;
  margin-bottom: 40px;
}

#pricing-3 .more-btn,
#pricing-4 .more-btn {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  OLD PRICE
/*------------------------------------------*/

.old-price {
  color: #aaa !important;
  font-weight: 700;
  text-decoration: line-through;
  margin-right: 10px;
}

/*------------------------------------------*/
/*  IMG BLOCK IMAGE
/*------------------------------------------*/

.img-block {
  text-align: center;
}

/*------------------------------------------*/
/*  MASONRY IMAGE
/*------------------------------------------*/

.masonry-wrap .masonry-image {
  position: relative;
  width: 33.33333%;
  margin-bottom: 30px;
  padding: 0 15px;
}

.masonry-image img {
  text-align: center;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/*------------------------------------------*/
/*  MASONRY IMAGES HOLDER
/*------------------------------------------*/

.masonry-image .hover-overlay,
.masonry-image .hover-overlay img {
  overflow: hidden;
}

/*------------------------------------------*/
/*  Image Hover Overlay
/*------------------------------------------*/

.hover-overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  max-height: 308px;
}

.hover-overlay img {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  overflow: hidden;
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
}

/*------------------------------------------*/
/*    Overlay Background 
/*------------------------------------------*/

.item-overlay {
  opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, 0.5);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/* ==========================================================================
  03. PRELOAD SPINNER 
  ========================================================================== */

#loading {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  z-index: 99999999;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

.spinner {
  position: absolute;
  width: 45px;
  height: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner .blob {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.spinner .blob.top {
  top: 0;
  -webkit-animation: blob-top 1s infinite ease-in;
  animation: blob-top 1s infinite ease-in;
}

.spinner .blob.bottom {
  top: 100%;
  -webkit-animation: blob-bottom 1s infinite ease-in;
  animation: blob-bottom 1s infinite ease-in;
}

.spinner .blob.left {
  left: 0;
  -webkit-animation: blob-left 1s infinite ease-in;
  animation: blob-left 1s infinite ease-in;
}

.spinner .move-blob {
  top: 0;
  -webkit-animation: blob-spinner-mover 1s infinite ease-in;
  animation: blob-spinner-mover 1s infinite ease-in;
}

@keyframes blob-bottom {
  25%,
  50%,
  75% {
    top: 50%;
    left: 100%;
  }
  100% {
    top: 0;
    left: 50%;
  }
}

@keyframes blob-left {
  25% {
    top: 50%;
    left: 0;
  }
  50%,
  100% {
    top: 100%;
    left: 50%;
  }
}

@keyframes blob-top {
  50% {
    top: 0;
    left: 50%;
  }
  75%,
  100% {
    top: 50%;
    left: 0;
  }
}

@keyframes blob-spinner-mover {
  0%,
  100% {
    top: 0;
    left: 50%;
  }
  25% {
    top: 50%;
    left: 100%;
  }
  50% {
    top: 100%;
    left: 50%;
  }
  75% {
    top: 50%;
    left: 0;
  }
}

/*------------------------------------------*/
/*  PRELOAD SPINNER COLOR
/*------------------------------------------*/

#loading {
  background-color: #f8f6f4;
}
.spinner .blob {
  border: 2px solid #f08080;
}
.spinner .move-blob {
  background: #f08080;
}

#loading.plum-loading {
  background-color: #f7f5f3;
}
.plum-loading .spinner .blob {
  border: 2px solid #d07ea7;
}
.plum-loading .spinner .move-blob {
  background: #d07ea7;
}

#loading.rose-loading {
  background-color: #f0f0f2;
}
.rose-loading .spinner .blob {
  border: 2px solid #fd1c79;
}
.rose-loading .spinner .move-blob {
  background: #fd1c79;
}

#loading.violet-red-loading {
  background-color: #f7f5f3;
}
.violet-red-loading .spinner .blob {
  border: 2px solid #da3b85;
}
.violet-red-loading .spinner .move-blob {
  background: #da3b85;
}

/* ==========================================================================
  04. HEADER & NAVIGATION
  ========================================================================== */

#header {
  width: 100%;
  display: block;
  padding-top: 0px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.headerwp {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.posrlt {
  position: relative;
}

/*------------------------------------------*/
/*  HEADER LOGO
/*------------------------------------------*/

.desktoplogo img,
.wscenterlogo img {
  width: auto;
  max-width: inherit;
  max-height: 45px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.wscenterlogo img {
  max-height: 50px;
}

.scroll .wscenterlogo img {
  max-height: 45px;
}

/*------------------------------------------*/
/*   NAVIGATION MENU
/*------------------------------------------*/

.wsmainfull {
  width: 100%;
  height: auto;
  z-index: 1031;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.tra-menu .wsmainfull {
  background-color: transparent !important;
  padding: 20px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.tra-menu.tra-menu-wide .wsmainfull {
  padding: 40px 0;
}

.white-menu .wsmainfull {
  background-color: #fff !important;
  padding: 10px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.dark-menu .wsmainfull {
  background-color: #1d293f !important;
  padding: 10px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.centered-menu .wsmenu {
  padding: 0 30px;
}

/*------------------------------------------*/
/*  HEADER LINK
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > a {
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  margin: 0 9px;
  padding: 10px 13px;
  line-height: 50px;
  text-decoration: none;
}

.navbar-dark .wsmenu > .wsmenu-list > li > a {
  color: #383838;
}

.navbar-light .wsmenu > .wsmenu-list > li > a {
  color: #fff;
}

.wsmenu > .wsmenu-list > li > a.last-link {
  padding: 10px 0px;
  margin: 0 0 0 7px;
}

.wsmenu > .wsmenu-list > li > a .wsarrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid;
  content: "";
  float: right;
  right: 0;
  height: 0;
  margin: 0 0 0 14px;
  position: absolute;
  text-align: right;
  top: 32px;
  width: 0;
}

/*------------------------------------------*/
/*  HEADER BUTTONS
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li a.btn {
  font-size: 0.85rem;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 14px;
  margin-left: 12px;
  padding: 5px 22px 4px;
}

/*------------------------------------------*/
/*  HEADER SUBMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > ul.sub-menu {
  padding: 12px 20px;
  width: auto;
  min-width: 200px;
  top: 65px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.wsmenu > .wsmenu-list > li > ul.sub-menu:before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: -8px;
  left: 40px;
  height: 0.9375rem;
  width: 0.9375rem;
  border-top: solid 1px #eee;
  border-left: solid 1px #eee;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -2;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  color: #2c3e50;
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
  padding: 5px 0 5px 5px;
}

/*------------------------------------------*/
/*   HALFMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu {
  top: 65px;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  width: 36%;
  padding: 15px 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu:before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: -8px;
  left: 40px;
  height: 0.9375rem;
  width: 0.9375rem;
  border-top: solid 1px #eee;
  border-left: solid 1px #eee;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -2;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li {
  border-bottom: none;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  color: #656565;
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  padding: 5px 0 5px 5px;
}

/*------------------------------------------*/
/*   MEGAMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu {
  padding: 15px 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.wsmenu > .wsmenu-list > li.mg_link:hover > a:after {
  content: "";
  background-color: #fff;
  position: absolute;
  bottom: -2px;
  left: 34%;
  height: 0.9375rem;
  width: 0.9375rem;
  border-top: solid 1px #eee;
  border-left: solid 1px #eee;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 9990;
  transition: all 1.4s ease-in-out;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 {
  width: 76% !important;
  left: 22%;
  padding: 20px 10px;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu .title p,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .title p {
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  margin: 10px 0 10px 0;
}

/*------------------------------------------*/
/*  NAVBAR SCROLL
/*------------------------------------------*/

.tra-menu .wsmainfull.scroll,
.white-menu .wsmainfull.scroll,
.tra-menu.tra-menu-wide .wsmainfull.scroll,
.dark-menu.dark-scroll .wsmainfull.scroll {
  background-color: #fff !important;
  padding: 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.scroll .white-color.header-socials span {
  color: #463853 !important;
}

/*------------------------------------------*/
/*  Navigation Menu
/*------------------------------------------*/

.tra-menu.navbar-light .scroll .wsmenu > .wsmenu-list > li a {
  color: #463853;
}

.tra-menu.navbar-light.dark-scroll .scroll .wsmenu > .wsmenu-list > li a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu > .wsmenu-list > li a {
  color: #fff;
}

.tra-menu.navbar-light.dark-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  .sub-menu
  li
  a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu > .wsmenu-list .sub-menu li a,
.tra-menu.navbar-light.dark-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  li
  .wsmegamenu
  a,
.tra-menu.navbar-dark.dark-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  li
  .wsmegamenu
  a {
  color: #666;
}

/*------------------------------------------*/
/*  Logo Image
/*------------------------------------------*/

.logo-white,
.logo-black {
  display: block;
}

.navbar-light .logo-black,
.navbar-dark .logo-white,
.tra-menu.navbar-dark .logo-white,
.tra-menu.navbar-light .logo-black,
.white-menu.navbar-dark .logo-white,
.dark-menu.navbar-light .logo-black,
.white-menu.navbar-light .logo-black,
.tra-menu.navbar-light .scroll .logo-white {
  display: none;
}

.tra-menu.navbar-light .scroll .logo-black {
  display: block;
}

/* ==========================================================================
  05. HERO
  ========================================================================== */

.hero-section .container {
  position: relative;
  z-index: 3;
}

/*------------------------------------------*/
/*  HERO SLIDER
/*------------------------------------------*/

.slider {
  position: relative;
  max-width: 100%;
  height: 560px;
}

.slider .slides {
  margin: 0;
  height: 560px;
}

.slider .slides li {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: inherit;
  overflow: hidden;
}

.slider .slides li img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.slider .slides li .caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.slider .slides li.active {
  z-index: 2;
}

/*------------------------------------------*/
/*  Slider Animation
/*------------------------------------------*/

.center-align {
  text-align: center;
}

/*------------------------------------------*/
/*  Slider Indicators
/*------------------------------------------*/

.slider .indicators {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: 0;
  z-index: 98;
}

.slider .indicators .indicator-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  margin: 0 7px;
  opacity: 0.5;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  border-radius: 50%;
}

.slider .indicators .indicator-item.active {
  background-color: #fff;
  opacity: 0.65;
}

/*------------------------------------------*/
/*  HERO-1
/*------------------------------------------*/

#hero-1 .slider {
  margin-top: 80px;
}

#hero-1 .caption-txt {
  margin-top: -10px;
}

#hero-1 .caption-txt h2 {
  font-size: 5.25rem;
  line-height: 1.25;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 30px;
}

#hero-1 .caption-txt h2 span {
  display: block;
}

/*------------------------------------------*/
/*  HERO-2
/*------------------------------------------*/

#hero-2 {
  position: relative;
  background-color: #eeb4c7;
  padding-top: 100px;
  margin-bottom: 60px;
}

.hero-2-txt {
  margin-top: -50px;
}

.hero-2-txt h2 {
  font-size: 5.35rem;
  line-height: 1.15;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.hero-2-txt p {
  color: #fdf2f6;
  padding-right: 10%;
  margin-bottom: 30px;
}

.hero-2-img {
  margin: 0 -50px -60px -50px;
}

.wave-shape-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/*------------------------------------------*/
/*  HERO-3
/*------------------------------------------*/

#hero-3 {
  background-image: url(../images/hero-3.jpg);
  padding-top: 175px;
  padding-bottom: 125px;
}

.hero-3-txt h2 {
  font-size: 4.35rem;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 10px;
}

.hero-3-txt p {
  font-size: 1.7rem;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  HERO-4
/*------------------------------------------*/

#hero-4 .slider {
  height: 530px;
  margin-top: 80px;
}

#hero-4 .slider .slides {
  height: 530px;
  margin: 0;
}

/*------------------------------------------*/
/*  HERO-5
/*------------------------------------------*/

.hero-5-overlay {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  padding-top: 180px;
  padding-bottom: 125px;
}

.hero-5-txt h2 {
  font-size: 4.75rem;
  font-weight: 500;
  font-style: italic;
  letter-spacing: -1px;
  margin-bottom: 10px;
}

.hero-5-txt p {
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  HERO-6
/*------------------------------------------*/

#hero-6 {
  padding-top: 170px;
}

.hero-6-txt h2 {
  line-height: 1.2;
  margin-bottom: 30px;
}

.hero-6-images {
  margin-top: 70px;
}

#img-6-1 img,
#img-6-2 img {
  text-align: center;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

#img-6-1:hover img,
#img-6-2:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

/*------------------------------------------*/
/*  HERO-7
/*------------------------------------------*/

#hero-7 .slider {
  height: 550px;
  margin-top: 140px;
}

#hero-7 .slider .slides {
  height: 550px;
  margin: 0;
}

#hero-7 .caption-txt {
  padding-left: 60px;
}

#hero-7 .caption-txt h2 {
  font-family: "Catamaran", sans-serif;
  font-size: 5rem;
  line-height: 1.1;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-bottom: 15px;
}

#hero-7 .caption-txt h2 span {
  display: block;
}

#hero-7 .caption-txt p {
  padding-right: 15%;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  HERO-8
/*------------------------------------------*/

#hero-8 {
  position: relative;
  padding-top: 160px;
  margin-bottom: 120px;
}

.hero-8-txt {
  position: relative;
  padding: 0 10%;
  z-index: 5;
}

.hero-8-txt h2 {
  font-size: 4.75rem;
  line-height: 1.25;
  font-weight: 500;
  font-style: italic;
}

.hero-8-img {
  position: relative;
  margin: 50px 4% -120px;
  z-index: 2;
}

/*------------------------------------------*/
/*  HERO-9
/*------------------------------------------*/

#hero-9 {
  background-image: url(../images/hero-9.jpg);
  padding-top: 260px;
  padding-bottom: 90px;
}

.hero-9-txt h2 {
  font-size: 4.35rem;
  line-height: 1.2;
  margin-bottom: 20px;
}

.hero-9-txt p {
  padding-right: 3%;
  margin-bottom: 30px;
}

/* ==========================================================================
  06. ABOUT
  ========================================================================== */

#about-6,
#about-7 {
  position: relative;
  margin-bottom: 60px;
}

#about-7 .container {
  padding-left: 25px;
}

#about-6 .container:after,
#about-7 .container:after {
  position: absolute;
  content: "";
  width: 85%;
  height: 100%;
  top: 0;
  z-index: -1;
}

#about-6 .container:after {
  left: 15%;
}

#about-7 .container:after {
  left: 0;
}

#about-8 {
  margin-top: 60px;
}

#about-9 {
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
}

#about-9:after {
  position: absolute;
  content: "";
  width: 65%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

#about-10 .container:after {
  position: absolute;
  content: "";
  width: 85%;
  height: 100%;
  top: 0;
  left: 15%;
  z-index: -1;
}

#about-12 {
  position: relative;
  z-index: 1;
}

/*------------------------------------------*/
/*  ABOUT TEXT
/*------------------------------------------*/

.txt-block.left-column,
.txt-table.left-column,
.img-block.left-column {
  padding-left: 5px;
  padding-right: 20px;
}

.txt-block.right-column,
.img-block.right-column {
  padding-left: 20px;
  padding-right: 5px;
}

#about-4 .txt-block.right-column {
  padding-left: 30px;
}

.about-6-txt {
  position: relative;
  padding: 80px 70px 80px 10px;
}

.about-7-txt {
  position: relative;
  padding-left: 60px;
  padding-right: 10px;
}

.about-8-txt {
  margin-top: 70px;
  padding-right: 50px;
  padding-left: 20px;
}

.about-9-txt {
  padding-left: 5px;
}

.about-10-txt {
  padding: 60px 70px 60px 10px;
}

.about-11-txt {
  position: relative;
  padding: 80px;
  margin-right: -80px;
  z-index: 7;
}

/*------------------------------------------*/
/*  ABOUT TYPOGRAPHY
/*------------------------------------------*/

.about-section h5,
.about-section h6 {
  margin-top: 12px;
  margin-bottom: 22px;
}

.about-section h4 {
  margin-bottom: 20px;
}

.about-section h3,
.about-section h2 {
  margin-bottom: 22px;
}

#about-2 .txt-block p,
#about-5 .txt-block p {
  margin-bottom: 0;
}

.about-section .btn {
  position: relative;
  z-index: 1;
  margin-top: 15px;
}

#about-1.about-section .btn,
#about-2.about-section .btn {
  margin-top: 5px;
}

/*------------------------------------------*/
/*  ABOUT TABLES
/*------------------------------------------*/

.table-rounded {
  padding: 45px 55px;
  border: 2px solid #232424;
}

.table-rounded.no-border {
  border: none;
}

.table-rounded.deepblue-border {
  border: 2px solid #204e5b;
}

.txt-table h5 {
  margin-bottom: 25px;
}

.txt-table table {
  margin-bottom: 0;
}

.txt-table .table td,
.txt-table .table th {
  font-size: 1.25rem;
  padding: 14px 0;
  border-top: none;
  border-bottom: 1px dashed #aaa;
}

.txt-table .table .last-tr td,
.txt-table .table .last-tr th {
  border-bottom: none;
}

td.text-end {
  font-weight: 600;
}

/*------------------------------------------*/
/*  ABOUT ACCORDION  
/*------------------------------------------*/

.accordion-wrapper {
  margin-bottom: 10px;
}

.accordion-item {
  background-color: transparent !important;
  border: none;
}

.accordion-thumb {
  margin: 0;
  padding: 20px 0;
  cursor: pointer;
  position: relative;
}

.accordion-item .accordion-thumb:before,
.accordion-item.is-active .accordion-thumb:before {
  font-family: Flaticon;
  font-weight: 300;
  content: "\f147";
  position: absolute;
  font-size: 0.8rem;
  top: 35px;
  left: 0;
}

.accordion-item.is-active .accordion-thumb:before {
  content: "\f163";
}

.accordion-panel {
  margin: 0;
  padding: 0;
  display: none;
}

.accordion-thumb h6,
.accordion-thumb h5,
.accordion-thumb h4 {
  line-height: 1;
  margin-bottom: 0;
  padding-left: 25px;
}

/*------------------------------------------*/
/*  ABOUT IMAGES
/*------------------------------------------*/

.about-6-img {
  position: relative;
  margin: 60px 40px -60px 0;
}

.about-7-img {
  position: relative;
  margin: 60px 0 -60px 40px;
}

.about-8-img {
  padding: 0 30px 0 0;
  margin-top: -60px;
}

.about-9-img {
  position: relative;
  margin-left: 60px;
}

.about-10-img {
  margin: 80px 20px 80px 0;
}

.about-11-img {
  position: relative;
  z-index: 2;
}

.about-12-img {
  background-image: url(../images/about-12-img.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  left: 53%;
}

/*------------------------------------------*/
/*  ABOUT IMAGE HOVER
/*------------------------------------------*/

.sbox-7 .item-overlay {
  background: rgba(240, 240, 240, 0.25);
}

.sbox-7:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.sbox-7:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
}

/* ==========================================================================
  07. SERVICES
  ========================================================================== */

#services-3,
#services-4 {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}

#services-3:after,
#services-4:after {
  position: absolute;
  content: "";
  width: 70%;
  height: 100%;
  top: 0;
  z-index: 2;
}

#services-3:after {
  left: 30%;
}

#services-4:after {
  left: 0;
}

/*------------------------------------------*/
/*  SERVICE BOXES WRAPPER
/*------------------------------------------*/

.sbox-3-wrapper {
  margin-left: 80px;
}

.sbox-4-wrapper {
  margin-right: 80px;
}

/*------------------------------------------*/
/*  SERVICE BOX 
/*------------------------------------------*/

.sbox-1 {
  text-align: center;
  border: 2px solid #dae3e0;
  padding: 50px 35px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.sbox-1:hover {
  background-color: rgba(201, 208, 206, 0.05);
  border: 2px solid rgba(201, 208, 206, 0.05);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sbox-2 {
  padding: 0 8%;
}

.sbox-3,
.sbox-4 {
  padding: 0 10px;
}

/*------------------------------------------*/
/*  SERVICE BOX ICON
/*------------------------------------------*/

.sbox-ico {
  margin-bottom: 20px;
}

.sbox-2 .sbox-ico {
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  SERVICE BOX TYPOGRAPHY
/*------------------------------------------*/

.sbox-1 h5,
.sbox-3 h5,
.sbox-4 h5 {
  margin-bottom: 13px;
}

.sbox-2 h5,
.sbox-5 h5 {
  margin-bottom: 10px;
}

.sbox-6 h5 {
  margin-top: 13px;
  margin-bottom: 0;
}

.sbox-1 p,
.sbox-2 p,
.sbox-3 p,
.sbox-4 p,
.sbox-5 p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Service Box link
/*------------------------------------------*/

h5.sbox-link a {
  position: relative;
  color: #201e29;
  padding-bottom: 6px;
  text-decoration: none;
}

h5.sbox-link a:after {
  content: "";
  background-color: #201e29;
  width: 20%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: 0.3s all ease-in;
  -moz-transition: 0.3s all ease-in;
  -o-transition: 0.3s all ease-in;
  transition: 0.3s all ease-in;
}

.sbox-1:hover h5.sbox-link a:after,
.sbox-2:hover h5.sbox-link a:after,
.sbox-3:hover h5.sbox-link a:after,
.sbox-4:hover h5.sbox-link a:after,
.sbox-6:hover h5.sbox-link a:after,
.sbox-7:hover h5.sbox-link a:after {
  width: 100%;
  opacity: 1;
}

/*------------------------------------------*/
/*  SERVICE IMAGE
/*------------------------------------------*/

.sbox-6 .item-overlay {
  background: rgba(20, 20, 20, 0.3);
}

.services-3-img {
  position: relative;
  margin-left: 0;
  margin-right: -50px;
}

.services-4-img {
  position: relative;
  margin-left: -50px;
  margin-right: 0;
}

.sbox-6-img {
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  Sbox Image Hover
/*------------------------------------------*/

.sbox-6:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.sbox-6:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
  bottom: 0;
}

/* ==========================================================================
  08. GALLERY
  ========================================================================== */

#gallery-2 .col {
  padding: 0 8px;
}

#img-2-1,
#img-2-2,
#img-2-3,
#img-2-4 {
  margin-bottom: 16px;
}

/*------------------------------------------*/
/*  MASONRY IMAGES HOLDER
/*------------------------------------------*/

#img-4-1 {
  margin-top: 60px;
}
#img-4-2 {
  margin-bottom: 30px;
}
#img-4-3 {
  margin-right: -30px;
}
#img-4-4 {
  margin-top: 60px;
  margin-bottom: 30px;
}
#img-4-5 {
  margin-left: 30px;
}
#img-4-6 {
  margin: 30px 10px 30px;
}

/*------------------------------------------*/
/*  MASONRY IMAGES HOLDER
/*------------------------------------------*/

.masonry-image .hover-overlay,
.masonry-image .hover-overlay img {
  overflow: hidden;
}

/*------------------------------------------*/
/*  IMAGE DESCRIPTION
/*------------------------------------------*/

.image-data,
.image-description {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.image-data {
  bottom: -40px;
}

.gallery-link,
.image-caption,
.gallery-5-txt {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.image-caption h6,
.image-caption h5 {
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.image-caption p {
  margin-bottom: 8px;
  -webkit-transform: translate3d(0, -15px, 0);
  transform: translate3d(0, -15px, 0);
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

/*------------------------------------------*/
/*    Image Hover Effect 
/*------------------------------------------*/

.gallery-image:hover img,
.masonry-image:hover img {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.gallery-image:hover .image-data,
.masonry-image:hover .image-data,
.gallery-image:hover .item-overlay,
.masonry-image:hover .item-overlay,
.gallery-image:hover .image-description,
.masonry-image:hover .image-description {
  opacity: 1;
  -moz-opacity: 1;
  bottom: 0;
}

.gallery-image:hover .image-caption h5,
.masonry-image:hover .image-caption h5,
.gallery-image:hover .image-caption p,
.masonry-image:hover .image-caption p {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* ==========================================================================
  09. VIDEO
  =========================================================================== */

#video-1,
.video-2-wrapper {
  background-image: url(../images/video.jpg);
  padding-top: 240px;
  padding-bottom: 240px;
}

.video-2-overlay {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 140px;
  padding-bottom: 140px;
}

/*------------------------------------------*/
/*  VIDEO TYPOGRAPHY
/*------------------------------------------*/

.video-2-txt h3 {
  margin-bottom: 15px;
}

.video-2-txt p {
  margin-bottom: 25px;
}

.video-2-txt span a {
  text-decoration: underline;
}

/* ==========================================================================
  10. TEAM
  =========================================================================== */

/*------------------------------------------*/
/*  TEAM MEMBER
/*------------------------------------------*/

.team-member {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

#team-2 .masonry-wrap .masonry-image {
  width: 50%;
  text-align: left;
  margin-bottom: 40px;
  padding: 0 15px;
}

/*------------------------------------------*/
/*  TEAM MEMBER PHOTO
/*------------------------------------------*/

.team-member-photo {
  position: relative;
  text-align: center;
  overflow: hidden;
}

/*------------------------------------------*/
/*  TEAM MEMBER TYPOGRAPHY
/*------------------------------------------*/

.team-member-data {
  margin-top: 30px;
}

.team-member-data span {
  margin-bottom: 13px;
}

.team-member-data h5 {
  line-height: 1;
  margin-bottom: 0;
}

.team-member .item-overlay {
  background: rgba(20, 20, 20, 0.4);
}

/*------------------------------------------*/
/*  Team Member Social Links
/*------------------------------------------*/

.tm-social ul {
  width: 100%;
  display: inline-block;
  padding-left: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 20;
  zoom: 1;
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.tm-social li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0 10px;
  padding: 0;
}

.tm-social span {
  margin-bottom: 0;
}

.tm-social a {
  display: block;
  text-decoration: none;
}

/*------------------------------------------*/
/*  Team Member Hover
/*------------------------------------------*/

.team-member:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.team-member:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
}

.team-member:hover .tm-social ul {
  bottom: 6%;
  opacity: 1;
  -moz-opacity: 1;
}

/*------------------------------------------*/
/*  CAREER BOX
/*------------------------------------------*/

.career-box {
  padding: 50px 45px 35px;
}

/*------------------------------------------*/
/*  CAREER BOX TITLE
/*------------------------------------------*/

.career-box-title {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.career-box-ico {
  width: 20%;
  display: inline-block !important;
  float: left;
  margin-top: -10px;
  text-align: left;
}

.career-box-title-txt {
  width: 80%;
  display: inline-block !important;
}

.career-box-title-txt h5 {
  line-height: 1;
  margin-bottom: 10px;
}

.career-box-btn {
  position: absolute;
  top: 5px;
  right: -10px;
}

/*------------------------------------------*/
/*  CAREER BOX TEXT
/*------------------------------------------*/

.career-box .simple-list p {
  margin-bottom: 8px;
}

/* ==========================================================================
  11. PRICING
  ========================================================================== */

#pricing-1,
#pricing-2 {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}

#pricing-1:after,
#pricing-2:after {
  position: absolute;
  content: "";
  width: 70%;
  height: 100%;
  top: 0;
  z-index: 2;
}

#pricing-1:after {
  left: 30%;
}

#pricing-2:after {
  left: 0;
}

/*------------------------------------------*/
/*  SERVICE BOXES WRAPPER
/*------------------------------------------*/

.pricing-1-wrapper {
  margin-left: 70px;
  padding-right: 5px;
}

.pricing-2-wrapper {
  margin-right: 70px;
  padding-left: 5px;
}

.pricing-3-wrapper {
  padding: 0 5px;
}

.p4-wrapper {
  padding: 75px 60px;
  overflow: hidden;
}

.pricing-5-wrapper {
  border: 1px solid #f5f5f5;
  padding: 55px 60px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.pricing-6-wrapper {
  padding: 0 5px;
}

.p7-wrapper {
  background-color: #3e3e3f;
  padding: 70px;
  overflow: hidden;
}

/*------------------------------------------*/
/*  PRICING TITLES
/*------------------------------------------*/

.pricing-title {
  line-height: 1;
  margin-bottom: 40px;
}

.pricing-7-title {
  text-align: center;
  border-bottom: 2px solid #fff;
  margin-bottom: 75px;
}

/*------------------------------------------*/
/*  PRICING LIST
/*------------------------------------------*/

.pricing-1-list li,
.pricing-2-list li,
.pricing-3-list li,
.pricing-5-list li,
.pricing-7-list li {
  margin-bottom: 18px;
}

.pricing-4-list li {
  margin-bottom: 25px;
}

.pricing-6-list li {
  margin-bottom: 15px;
}

.pricing-1-list li:last-child,
.pricing-2-list li:last-child,
.pricing-3-list li:last-child,
.pricing-4-list li:last-child,
.pricing-5-list li:last-child,
.pricing-6-list li:last-child,
.pricing-7-list li:last-child {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  PRICING DETAILS
/*------------------------------------------*/

.detail-price {
  margin: 0;
  display: table;
}

.price-name {
  position: relative;
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  padding: 0 10px 0 0;
}

.price-dots {
  position: relative;
  display: table-cell;
  height: 3px;
  width: 98%;
  z-index: 1;
  background-image: radial-gradient(
    circle closest-side,
    #878889 99%,
    transparent 100%
  );
  background-position: 50% 100%;
  background-size: 5px 2px;
  background-repeat: repeat-x;
}

.price-number {
  display: table-cell;
  width: 1%;
  padding: 0 0 0 10px;
  white-space: nowrap;
}

.price-name h5,
.price-number h5 {
  position: relative;
  line-height: 1;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Pricing Item Description
/*------------------------------------------*/

.price-txt {
  margin-top: 3px;
}

.pricing-4-item .price-txt {
  margin-top: 8px;
}

.price-txt p {
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 0;
}

.pricing-4-item .price-txt p {
  margin-top: 10px;
  margin-bottom: 0;
}

.price-txt span {
  display: block;
  margin-bottom: 0;
  padding-right: 3px;
}

.pricing-4-item .price-name h5 {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.pricing-notice p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  PRICING IMAGE
/*------------------------------------------*/

.pricing-1-img {
  position: relative;
  margin-right: -30px;
}

.pricing-2-img {
  position: relative;
  margin-left: -30px;
}

.pricing-4-item .item-overlay {
  background: rgba(240, 240, 240, 0.35);
}

.pricing-5-img {
  margin-right: -60px;
}

/*------------------------------------------*/
/*  Image Hover Effect 
/*------------------------------------------*/

.pricing-4-item:hover img {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.pricing-4-item:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
}

.pricing-4-item:hover .price-name h5 {
  color: #656565;
}

/* ==========================================================================
  12. GIFT CARDS
  ========================================================================== */

/*------------------------------------------*/
/*  GIFT CARDS TEXT
/*------------------------------------------*/

.cards-2-txt {
  padding-left: 5px;
  padding-right: 20px;
}

/*------------------------------------------*/
/*  GIFT CARD
/*------------------------------------------*/

.gift-card {
  background-image: url(../images/gift-card.png);
  text-align: center;
  padding: 80px 35px;
  margin-bottom: 40px;
}

.gift-card h5.h5-lg,
.gift-card h5.h5-xl {
  margin-bottom: 25px;
}

.gift-card span {
  font-family: "Open Sans", sans-serif;
  font-size: 5.25rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0;
}

.gift-card sup {
  font-family: "Open Sans", sans-serif;
  font-size: 3.5rem;
  font-weight: 400;
  top: -9px;
  right: 3px;
}

.gift-card p {
  margin-top: 15px;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  GIFT CARDS TEXT TYPOGRAPHY
/*------------------------------------------*/

.cards-2-txt h3 {
  margin-bottom: 15px;
}

.cards-2-txt p {
  margin-bottom: 30px;
}

/* ==========================================================================
  13. BRANDS
  ========================================================================== */

#brands-1 {
  padding-top: 80px;
  padding-bottom: 80px;
}

/*------------------------------------------*/
/*  BRANDS LOGO HOLDER
/*------------------------------------------*/

.brand-logo {
  padding: 0 20px;
}

.brands-section .owl-dots {
  display: none;
}

/* ==========================================================================
  14. TESTIONIALS
  ========================================================================== */

.reviews-section {
  position: relative;
}

/*------------------------------------------*/
/*  REVIEWS
/*------------------------------------------*/

.review-1 {
  text-align: center;
  margin: 0 15px 40px;
}

.review-2-txt {
  text-align: center;
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*  QUOTE ICON
/*------------------------------------------*/

.review-1 .quote-icon {
  position: relative;
  margin-left: 5px;
  margin-bottom: -50px;
  opacity: 0.08;
}

.review-2 .quote-icon {
  position: relative;
  margin-bottom: -70px;
  opacity: 0.08;
}

/*------------------------------------------*/
/*  REVIEWS TYPOGRAPHY
/*------------------------------------------*/

span.testimonial-autor {
  display: block;
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 0;
}

.review-2 span.testimonial-autor {
  margin-top: 0;
  margin-bottom: 70px;
}

/*------------------------------------------*/
/*  Testimonial Message Text
/*------------------------------------------*/

.reviews-section h6 {
  margin-bottom: 0;
}

.review-2-txt p {
  font-size: 1.9rem;
  line-height: 1.45;
  font-weight: 400;
  padding: 0 10%;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  Reviews FlexSlider Navigation
/*------------------------------------------*/

.flex-control-paging li a {
  background: rgba(90, 90, 90, 0.25);
  width: 10px;
  height: 10px;
  border: 2px solid rgba(90, 90, 90, 0.02);
}

.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active {
  background: rgba(111, 94, 77, 0.65);
  border: 2px solid rgba(111, 94, 77, 0.2);
}

/*------------------------------------------*/
/*  Carousel Dots
/*------------------------------------------*/

.reviews-section button {
  background-color: transparent;
  border: none;
  padding: 0 2px;
}

.owl-theme .owl-dots .owl-dot span {
  margin: 0 7px;
}

.owl-dots button:focus {
  outline: none;
  text-decoration: none;
}

/* ==========================================================================
  15. PROMO
  =========================================================================== */

#promo-3 .item-overlay {
  opacity: 1;
  background: rgba(20, 20, 20, 0.3);
}

#promo-4:before,
#promo-4:after {
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  content: "";
  z-index: 2;
}

#promo-4:before {
  background-image: url(../images/pb_4-1.jpg);
  width: 49%;
  height: 90%;
  top: 5%;
}

#promo-4:after {
  background-image: url(../images/pb_4-2.jpg);
  width: 49%;
  height: 100%;
  top: 0;
  left: 51%;
}

.pbox-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.03)
  );
  background-image: linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.03)
  );
  padding: 100px 0 0;
}

.pbox-caption {
  position: absolute;
  width: 80%;
  bottom: 30px;
  left: 25px;
  z-index: 1;
}

.pbox-3-caption {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  z-index: 3;
}

#pb-4-1 {
  padding: 100px 80px 100px 30px;
}

#pb-4-2 {
  padding: 100px 30px 100px 80px;
}

.pbox-caption h5 {
  margin-bottom: 0;
}

#promo-2 .promo-box-txt h5 {
  margin-bottom: 0;
}

.pbox-3-caption h4 {
  margin-bottom: 30px;
}

#pb-4-1 h3 {
  font-weight: 500;
  font-style: italic;
  margin-bottom: 0;
}

#pb-4-1 h2 {
  letter-spacing: -2px;
  margin-bottom: 40px;
}

#pb-4-2 h2 {
  font-size: 9.75rem;
  line-height: 1;
  letter-spacing: -3px;
  margin-bottom: 35px;
}

#pb-4-2 h2 span {
  display: block;
}

.pbox-caption p {
  margin-bottom: 12px;
}

#promo-2 p.txt-upcase {
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*  Promo Box link
/*------------------------------------------*/

h5.pbox-link a {
  position: relative;
  color: #fff;
  padding-bottom: 8px;
  text-decoration: none;
}

h5.pbox-link a:after {
  content: "";
  background-color: #fff;
  width: 20%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: 0.3s all ease-in;
  -moz-transition: 0.3s all ease-in;
  -o-transition: 0.3s all ease-in;
  transition: 0.3s all ease-in;
}

.promo-box:hover h5.pbox-link a:after {
  width: 100%;
  opacity: 1;
}

/*------------------------------------------*/
/*  PROMO BOX IMAGE
/*------------------------------------------*/

.promo-box .item-overlay {
  background: rgba(20, 20, 20, 0.2);
}

#promo-1 .promo-box:hover img,
#promo-2 .promo-box:hover img,
#promo-3 .promo-box:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

/* ==========================================================================
  16. BANNER
  ========================================================================== */

#banner-1 {
  background-image: url(../images/banner-1.jpg);
  padding-top: 90px;
  padding-bottom: 90px;
}

.banner-2-wrapper {
  background-image: url(../images/banner-2.jpg);
  padding-top: 70px;
  padding-bottom: 80px;
}

#banner-3 {
  background-image: url(../images/banner-3.jpg);
}

#banner-4 {
  position: relative;
  background-image: url(../images/banner-4.jpg);
}

#banner-4:after {
  position: absolute;
  content: "";
  width: 55%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.banner-5-wrapper {
  background-image: url(../images/banner-5.jpg);
  padding: 80px;
}

#banner-6 {
  background-image: url(../images/banner-6.jpg);
  padding-top: 180px;
  padding-bottom: 180px;
}

/*------------------------------------------*/
/*  BANNER TEXT
/*------------------------------------------*/

.banner-3-txt {
  text-align: center;
  padding: 90px 0;
}

.banner-4-txt {
  text-align: center;
  padding: 100px 80px 100px 20px;
}

/*------------------------------------------*/
/*  BANNER TYPOGRAPHY
/*------------------------------------------*/

.banner-1-txt h5,
.banner-1-txt h4 {
  line-height: 1;
  font-weight: 400;
  letter-spacing: 2px;
  margin-bottom: 35px;
}

.banner-1-txt h3 {
  font-size: 6rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0;
}

.banner-1-txt h2 {
  font-size: 8.25rem;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 30px;
}

.banner-2-txt h2,
.banner-5-txt h2,
.banner-5-txt h3 {
  margin-bottom: 30px;
}

.banner-3-txt h4,
.banner-4-txt h4 {
  line-height: 1;
  font-weight: 400;
  margin-bottom: 30px;
}

.banner-3-txt h2,
.banner-4-txt h2 {
  font-size: 6.25rem;
  line-height: 1;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.banner-3-txt h5,
.banner-4-txt h5 {
  line-height: 1.4;
  font-weight: 400;
  padding: 0 18%;
  margin-bottom: 25px;
}

.banner-3-txt p,
.banner-4-txt p {
  margin-top: 20px;
  margin-bottom: 0;
}

.banner-6-txt a {
  text-decoration: underline;
}

/* ==========================================================================
  17. FAQs
  =========================================================================== */

#faqs-1 .question {
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*  FAQs TYPOGRAPHY
/*------------------------------------------*/

#faqs-1 .question h5 {
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  MORE QUESTIONS BUTTON
/*------------------------------------------*/

.more-questions {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* ==========================================================================
  18. NEWSLETTER
  ========================================================================== */

.newsletter-wrapper {
  padding: 70px 80px 40px;
}

#newsletter-1 .newsletter-form {
  padding: 0 15%;
}

/*------------------------------------------*/
/*  NEWSLETTER TYPOGRAPHY
/*------------------------------------------*/

#newsletter-1 .newsletter-form p {
  text-align: center;
  padding: 0 5%;
  margin-top: 30px;
  margin-bottom: 0;
}

#newsletter-1 .newsletter-form p a {
  font-weight: 700;
  text-decoration: underline;
}

/*------------------------------------------*/
/*   Newsletter Form Input
/*------------------------------------------*/

.newsletter-section .form-control {
  height: 60px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #666;
  font-size: 1.1875rem;
  font-weight: 300;
  padding: 0 20px;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.newsletter-section .input-group > .custom-select:not(:last-child),
.newsletter-section .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.newsletter-section .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Newsletter Form Input Placeholder
/*------------------------------------------*/

.newsletter-section .form-control::-moz-placeholder {
  color: #999;
}
.newsletter-section .form-control:-ms-input-placeholder {
  color: #999;
}
.newsletter-section .form-control::-webkit-input-placeholder {
  color: #999;
}

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.newsletter-section .btn {
  width: 100%;
  height: 60px;
  padding: 13px 45px 10px;
  margin-left: 10px;
}

/*------------------------------------------*/
/*  Newsletter Form Notification
/*------------------------------------------*/

.newsletter-section .form-notification {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1;
  margin: 7px 0 0;
}

.newsletter-section .form-notification.error {
  color: #f6412d !important;
}

.newsletter-section .form-notification.valid {
  color: #48af4b !important;
}

/* ==========================================================================
  19. BLOG
  ========================================================================== */

.blog-2-post {
  margin-bottom: 10px;
}

/*------------------------------------------*/
/*  BLOG POST TYPOGRAPHY
/*------------------------------------------*/

.blog-1-post .blog-post-txt,
.blog-2-post .blog-post-txt {
  margin-top: 30px;
}

.blog-post-txt h6,
.blog-post-txt h5 {
  margin-bottom: 10px;
}

.blog-post-txt h5 a {
  color: #201e29;
}

.blog-post-txt h5 a:hover {
  text-decoration: underline;
}

p.post-tag {
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  margin-bottom: 20px;
}

p.post-tag span {
  padding: 0 4px;
}

.blog-post-txt p.p-lg {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Blog Post Image
/*------------------------------------------*/

.blog-1-post .item-overlay,
.blog-2-post .item-overlay {
  background: rgba(20, 20, 20, 0.3);
}

/*------------------------------------------*/
/*  Blog Post Image Hover Effect 
/*------------------------------------------*/

.blog-1-post:hover img,
.blog-2-post:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.blog-1-post:hover .item-overlay,
.blog-2-post:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
}

/* ==========================================================================
  20. SINGLE BLOG POST
  ========================================================================== */

.post-content {
  padding: 0 5%;
}

/*------------------------------------------*/
/*  Single Post Title
/*------------------------------------------*/

.single-post-title h3,
.single-post-title h2 {
  padding: 0 3%;
  margin-bottom: 20px;
}

.single-post-title p.post-tag {
  font-size: 0.95rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

.single-post-title p.post-tag span {
  padding: 0 8px;
}

.single-post-title p.post-tag a {
  color: #1d293f;
  font-weight: 700;
}

/*------------------------------------------*/
/*  Single Post Text
/*------------------------------------------*/

.single-post-txt h5 {
  margin-top: 20px;
  margin-bottom: 22px;
}

.single-post-txt span.txt-700 {
  color: #1d293f;
}

/*------------------------------------------*/
/*  Single Post Image
/*------------------------------------------*/

.post-inner-img {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

/*------------------------------------------*/
/*  POST SHARE LINKS
/*------------------------------------------*/

.post-share-links {
  border-top: 1px solid #ccc;
  margin-top: 60px;
  padding-top: 60px;
}

.post-tags-list span a {
  color: #999;
  background-color: #f6f7f8;
  font-size: 0.8rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  padding: 10px 16px 9px;
  margin-right: 5px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/*------------------------------------------*/
/*  POST SHARE ICONS
/*-----------------------------------------*/

.post-share-links .share-social-icons {
  display: inline-block;
  padding-left: 0;
  margin-top: 3px;
}

.post-share-links .share-social-icons li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}

.post-share-links .share-social-icons a.share-ico span {
  opacity: 0.75;
  margin-top: 3px;
  margin-left: 20px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.post-share-links .share-social-icons a.share-ico:hover span {
  opacity: 1;
}

/*------------------------------------------*/
/*  POST COMMENTS
/*------------------------------------------*/

.post-comments {
  padding-top: 100px;
}

.post-comments hr {
  margin-top: 24px;
  margin-bottom: 40px;
}

.post-comments h5.h5-lg,
.post-comments h5.h5-xl {
  margin-bottom: 60px;
}

.post-comments img {
  width: 68px;
  height: 68px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.comment-body {
  margin-left: 25px;
}

.comment-meta {
  margin-bottom: 10px;
}

.comment-meta h6 {
  line-height: 1;
  margin-bottom: 2px;
}

.comment-date {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

/*------------------------------------------*/
/*  Comment Reply 
/*------------------------------------------*/

.btn-reply {
  margin-left: 3px;
}

.btn-reply a {
  font-size: 17px;
  font-weight: 400;
}

.btn-reply span {
  position: relative;
  top: 3px;
  margin-right: 4px;
  left: 3px;
}

.btn-reply.ico-20 [class*="flaticon-"]:before,
.btn-reply.ico-20 [class*="flaticon-"]:after {
  font-size: 0.975rem;
}

.btn-reply a:hover {
  color: #222;
}

/*------------------------------------------*/
/*  SINGLE POST COMMENT FORM
/*------------------------------------------*/

.post-comments #leave-comment h5 {
  padding-bottom: 0;
  margin-top: 80px;
  margin-bottom: 5px;
  border: none;
}

.comment-form {
  position: relative;
  margin-top: 60px;
}

.comment-form p {
  line-height: 1;
  font-weight: 700;
  margin-bottom: 15px;
  padding-left: 8px;
  display: block;
}

.comment-form .form-control {
  height: 56px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 0 0;
  color: #5f5842;
  font-size: 17px;
  line-height: 1;
  font-weight: 300;
  padding: 0 15px;
  margin-bottom: 22px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.comment-form .form-control:focus {
  outline: 0px none;
  box-shadow: none;
}

.comment-form textarea {
  min-height: 220px;
}
.comment-form textarea.form-control {
  padding: 20px 15px;
}

/*------------------------------------------*/
/*  Comment Form Button
/*------------------------------------------*/

.comment-form .btn {
  margin-top: 15px;
}

.comment-form .btn:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Comment Form Message
/*------------------------------------------*/

.comment-form-msg {
  width: 100% !important;
  display: block;
  margin-top: 15px;
  padding-left: 0;
}

.comment-form .loading {
  color: #00b2e4;
  font-size: 18px;
  font-weight: 400;
}

.comment-form .error {
  color: #e74c3c;
  font-size: 14.5px;
  font-weight: 300;
  margin-bottom: 20px;
  padding-left: 10px;
}

.comment-form .form-control.error {
  font-size: 17px !important;
}

/* ==========================================================================
  21. BOOKING
  ========================================================================== */

.booking-form .col-md-6,
.booking-form .col-lg-6,
.booking-form .col-md-12 {
  padding-left: 10px;
  padding-right: 10px;
}

.booking-1-wrapper,
.booking-2-wrapper {
  position: relative;
  border: 1px solid #f5f5f5;
  padding: 65px 40px 55px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  z-index: 3;
}

.booking-2-txt {
  position: relative;
  z-index: 2;
  padding: 90px 90px 90px 100px;
  margin-right: -60px;
}

.booking-2-txt:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}

/*------------------------------------------*/
/*  BOOKING TYPOGRAPHY
/*------------------------------------------*/

.booking-1-wrapper .section-id,
.booking-2-wrapper .section-id {
  margin-bottom: 16px;
}

.booking-1-wrapper h4,
.booking-2-wrapper h4 {
  margin-bottom: 35px;
}

.booking-2-txt h5 {
  margin-bottom: 15px;
}

.booking-2-hours {
  margin-top: 20px;
}

.booking-2-hours p {
  margin-bottom: 3px;
}

/*------------------------------------------*/
/*  BOOKING IMAGE
/*------------------------------------------*/

.booking-1-img {
  margin-right: -60px;
}

/*------------------------------------------*/
/*  BOOKING FORM
/*------------------------------------------*/

.booking-form .form-control,
.booking-form .form-select {
  height: 52px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 0 0;
  color: #5f5842;
  font-size: 17px;
  line-height: 1;
  font-weight: 300;
  padding: 0 12px;
  margin-bottom: 22px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/*------------------------------------------*/
/*  Booking Form Placeholder
/*------------------------------------------*/

.booking-form .form-control::-moz-placeholder {
  color: #5f5842;
}
.booking-form .form-control:-ms-input-placeholder {
  color: #5f5842;
}
.booking-form .form-control::-webkit-input-placeholder {
  color: #5f5842;
}

/*------------------------------------------*/
/*  Booking Form Input Focus
/*------------------------------------------*/

.booking-form .form-control:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Booking Form Message
/*------------------------------------------*/

.booking-form-msg {
  width: 100% !important;
  display: block;
  margin-top: 10px;
  padding-left: 0;
}

.booking-form .loading {
  color: #00b2e4;
  font-size: 18px;
  font-weight: 400;
}

.booking-form .error {
  color: #e74c3c;
  font-size: 14.5px;
  font-weight: 300;
  margin-bottom: 20px;
}

.booking-form .form-control.error {
  font-size: 17px !important;
}

/* ==========================================================================
  22. CONTACTS
  ========================================================================== */

.contacts-1-wrapper {
  padding: 0 4%;
}

.contacts-2-wrapper {
  padding: 0 5%;
}

.contacts-2-wrapper .title-01 {
  margin-top: 60px;
}

.contacts-3-wrapper {
  padding: 0 6%;
}

/*------------------------------------------*/
/*  CONTACT BOX
/*------------------------------------------*/

.cbox-3 {
  position: relative;
  height: 300px;
}

.cbox-3-txt {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
  z-index: 3;
}

.cbox-2 h5 {
  line-height: 1;
  margin-bottom: 20px;
}

.cbox-3 h5 {
  margin-bottom: 18px;
}

.cbox-1 p,
.cbox-2 p {
  font-weight: 400;
  margin-bottom: 3px;
}

.cbox-3 p {
  font-weight: 400;
  padding: 0 6%;
  margin-bottom: 20px;
}

.cbox-2 p a,
.cbox-1 p.p-xl,
.cbox-1 p.p-xl a {
  color: #201e29;
  font-weight: 600;
}

.cbox-2 p span {
  color: #201e29;
  font-weight: 600;
  margin-left: 10px;
}

#contacts-3 .cbox-2 p span {
  margin-left: 15px;
}

.contacts-section .tra-link {
  font-size: 0.95rem;
  font-weight: 700;
  margin-top: 12px;
}

.cbox-1 a,
.cbox-2 a {
  text-decoration: underline;
}

/*------------------------------------------*/
/*  CONTACT FORM
/*------------------------------------------*/

.contact-form .form-control {
  height: 56px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 0 0;
  color: #5f5842;
  font-size: 17px;
  line-height: 1;
  font-weight: 300;
  padding: 0 15px;
  margin-bottom: 22px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

/*------------------------------------------*/
/*  Contact Form Textarea
/*------------------------------------------*/

.contact-form textarea {
  min-height: 220px;
}
.contact-form textarea.form-control {
  padding: 20px 15px;
}

/*------------------------------------------*/
/*  Contact Form Placeholder
/*------------------------------------------*/

.contact-form .form-control::-moz-placeholder {
  color: #5f5842;
}
.contact-form .form-control:-ms-input-placeholder {
  color: #5f5842;
}
.contact-form .form-control::-webkit-input-placeholder {
  color: #5f5842;
}

/*------------------------------------------*/
/*  Contact Form Input Focus
/*------------------------------------------*/

.contact-form .form-control:focus {
  outline: 0px none;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: #555;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

/*------------------------------------------*/
/*  Contact Form Message
/*------------------------------------------*/

.contact-form-msg {
  width: 100% !important;
  display: block;
  margin-top: 10px;
  padding-left: 0;
}

.contact-form .loading {
  color: #00b2e4;
  font-size: 18px;
  font-weight: 400;
}

.contact-form .error {
  color: #e74c3c;
  font-size: 14.5px;
  font-weight: 300;
  margin-bottom: 20px;
}

.contact-form .form-control.error {
  font-size: 17px !important;
}

/* ==========================================================================
  23. GOOGLE MAP
  =========================================================================== */

.google-map {
  position: relative;
  padding: 0px 0px 85%;
  height: 0px;
  overflow: hidden;
}

#gmap .google-map,
#gmap-1 .google-map {
  padding: 0px 0px 36%;
}

.footer .google-map {
  padding: 0px 0px 70%;
}

.google-map iframe,
.google-map object,
.google-map embed {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0;
}

/* ==========================================================================
  24. FOOTER
  ========================================================================== */

.footer {
  padding-top: 100px;
  padding-bottom: 40px;
}

#footer-1 .footer-info {
  padding-left: 3%;
}

#footer-2 .footer-contacts {
  padding-left: 30%;
  padding-right: 10%;
}

/*------------------------------------------*/
/*  FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h6,
.footer h5,
.footer h4 {
  line-height: 1;
  margin-bottom: 25px;
}

.footer-contacts p,
#footer-3 .footer-info p {
  margin-bottom: 3px;
}

#footer-2 .footer-contacts p {
  margin-bottom: 2px;
}

#footer-1 .footer-info p,
#footer-1 .footer-contacts p {
  font-weight: 400;
  margin-bottom: 3px;
}

.footer-info a,
.footer-contacts a {
  text-decoration: underline;
}

.footer-info h5.booking-link {
  line-height: 1;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 0;
}

#footer-2 h4.h4-xs,
#footer-2 h4.h4-sm {
  margin-top: 20px;
  margin-bottom: 20px;
}

#footer-2 h4.h4-xs a,
#footer-2 h4.h4-sm a {
  text-decoration: none;
}

/*------------------------------------------*/
/*  FOOTER LINKS
/*------------------------------------------*/

.footer-links li {
  width: auto !important;
  display: block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
  padding: 0;
}

.footer-links li p {
  margin-bottom: 5px;
}

/*------------------------------------------*/
/*   FOOTER SOCIAL LINKS
/*------------------------------------------*/

.foo-socials {
  display: inline-block;
  margin-top: 20px;
}

.foo-socials li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0 12px 0 0;
  padding: 0;
}

.foo-socials a {
  display: block;
  text-decoration: none;
}

.foo-socials.ico-20 [class*="flaticon-"]:before,
.foo-socials.ico-20 [class*="flaticon-"]:after {
  font-size: 1.4rem;
}

/*------------------------------------------*/
/*  FOOTER INSTAGRAM IMAGES
/*------------------------------------------*/

.footer-img li {
  display: inline-block !important;
  margin: 0 0 10px 6px;
}

.footer-img img.insta-img {
  width: 110px;
  height: 110px;
}

.foo-img .item-overlay {
  background: rgba(40, 40, 40, 0.2);
}

.foo-img:hover img {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.foo-img:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
}

/*------------------------------------------*/
/*  FOOTER NEWSLETTER FORM
/*------------------------------------------*/

.footer-form p {
  font-size: 1rem;
  margin-top: 25px;
}

.footer-form p a {
  font-weight: 700;
  text-decoration: underline;
}

.footer-form .form-control {
  height: 56px;
  color: #666;
  font-size: 1.1rem;
  padding: 0 15px;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.footer-form .form-control:focus {
  border: 1px solid #373b4d;
  border-right: none;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Footer Form Placeholder
/*------------------------------------------*/

.footer-form .form-control::-moz-placeholder {
  color: #6c757d;
}
.footer-form .form-control:-ms-input-placeholder {
  color: #6c757d;
}
.footer-form .form-control::-webkit-input-placeholder {
  color: #6c757d;
}

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.footer-form .btn {
  font-size: 0.85rem;
  height: 56px;
}

/*------------------------------------------*/
/*  Footer Form Notification
/*------------------------------------------*/

.footer-form .form-notification {
  color: #e03a3e;
  font-size: 0.95rem;
  line-height: 1.25;
  font-weight: 400;
  margin-top: 12px;
  margin-left: 5px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER
/*------------------------------------------*/

.bottom-footer {
  padding-top: 50px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER LINKS
/*------------------------------------------*/

.bottom-footer-list li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
}

.bottom-footer-list span {
  position: relative;
  top: 3px;
  padding: 0 8px;
}

.bottom-footer-list li p {
  display: inline-block;
  float: left;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  FOOTER COPYRIGHT
/*------------------------------------------*/

.footer-copyright p {
  margin-bottom: 0;
}

/* ==========================================================================
  25. INNER PAGE WRAPPER
  =========================================================================== */

.inner-page-hero {
  padding-top: 180px;
}

/*------------------------------------------*/
/*  INNER PAGE HERO
/*------------------------------------------*/

.page-hero-section {
  background: no-repeat center center;
  background-size: cover;
  padding-top: 160px;
  padding-bottom: 110px;
}

#about-page.page-hero-section {
  background-image: url(../images/about-page.jpg);
}

#booking-page.page-hero-section {
  background-image: url(../images/booking-page.jpg);
}

#careers-page.page-hero-section {
  background-image: url(../images/careers-page.jpg);
}

#contacts-page.page-hero-section {
  background-image: url(../images/contacts-page.jpg);
}

#faqs-page.page-hero-section {
  background-image: url(../images/faqs-page.jpg);
}

#gift-cards-page.page-hero-section {
  background-image: url(../images/gift-cards-page.jpg);
}

#pricing-page.page-hero-section {
  background-image: url(../images/pricing-page.jpg);
}

#team-page.page-hero-section {
  background-image: url(../images/team-page.jpg);
}

/*------------------------------------------*/
/*  INNER PAGE HERO TYPOGRAPHY
/*------------------------------------------*/

.page-hero-section h3,
.page-hero-section h2 {
  margin-bottom: 20px;
}

/* ==========================================================================
  26. BREADCRUMB
  =========================================================================== */

#breadcrumb {
  text-align: center;
}

.breadcrumb-nav {
  display: inline-block;
  margin: 0 auto;
}

.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-radius: 0;
  width: 100%;
}

/*------------------------------------------*/
/*  BREADCRUMB TYPOGRAPHY
/*------------------------------------------*/

.breadcrumb-item {
  color: #fff;
  font-size: 0.85rem !important;
  line-height: 1 !important;
  font-weight: 700;
  letter-spacing: 0.1865rem;
  text-transform: uppercase;
}

.breadcrumb-item a {
  color: #fff;
  text-decoration: underline;
}

.breadcrumb-item.active,
.breadcrumb-item1 a:hover {
  color: #fff;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-size: 1.15rem;
  display: inline-block;
  padding-right: 16px;
  margin-top: -3px;
  color: #fff;
  content: "\00bb";
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 19px;
}

/* ==========================================================================
  27. PAGE PAGINATION
  =========================================================================== */

.page-link {
  font-size: 1.15rem;
  font-weight: 700;
  padding: 3px 13px;
  margin: 0 8px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid transparent;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination span {
  position: relative;
  font-size: 0.95rem;
  top: 2px;
}

.pagination.ico-20 [class*="flaticon-"]:before,
.pagination.ico-20 [class*="flaticon-"]:after {
  font-size: 0.85rem;
}

.page-link {
  color: #333233;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #333233;
  border: 1px solid #333233;
}

.page-item.disabled .page-link {
  color: #333233;
  background-color: transparent;
  border: 1px solid transparent;
}

.page-link:hover {
  color: #333233;
  background-color: transparent;
  border: 1px solid #333233;
}

.page-link:focus {
  background-color: transparent;
  color: #333233;
  border: 1px solid transparent;
  box-shadow: 0 0;
}

/* ==========================================================================
  28. SCROLL TO TOP
  ========================================================================== */

#scrollUp {
  display: none;
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background-image: url(../images/back-to-top.png);
  background-repeat: no-repeat;
  background-position: 50% 48%;
  background-color: rgba(10, 10, 10, 0.25);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

#scrollUp:hover {
  background-color: rgba(10, 10, 10, 0.85);
}

nav a#pull {
  display: none;
}

/* ==========================================================================
   STYLE CHANGER 
  ========================================================================== */

#stlChanger {
  position: fixed;
  z-index: 9999;
  font-size: 0.9375rem;
  overflow: hidden;
  right: -230px;
  top: 100px;
  cursor: pointer;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

#stlChanger.open {
  right: 0px;
}

#stlChanger .bgChanger {
  min-width: 280px;
  min-height: 280px;
}

#stlChanger .blockChanger {
  width: 230px;
}

#stlChanger .chBody {
  background: #282920;
  width: 230px;
  position: relative;
  overflow: hidden;
  border: 1px solid #282920;
  height: 550px;
  overflow: scroll;
  overflow-x: hidden;
  direction: rtl;
  margin-left: 50px;
}

#stlChanger .chBut {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 1000000;
  text-align: center;
  border-left: none;
  padding: 0 0 0 2px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}

#stlChanger .chBut.icon-xs [class*="flaticon-"]:before,
#stlChanger .chBut.icon-xs [class*="flaticon-"]:after {
  color: #fff;
  font-size: 2.15rem;
  line-height: 48px !important;
}

#stlChanger p {
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#stlChanger .stBgs a {
  text-decoration: none;
  width: 50px;
  height: 50px;
  float: left;
  padding: 0;
  margin: 0 5px 8px;
  cursor: pointer;
  opacity: 1;
}

#stlChanger .stBgs a img {
  width: 50px;
  height: 50px;
}

.stBlock1 {
  margin: 0 20px 25px 20px;
}

.stBlock .btn {
  width: 100%;
  font-size: 0.785rem;
  line-height: 1;
  padding: 13px 18px;
  margin-top: 15px;
}

.s_1 img {
  opacity: 1;
  margin-bottom: 15px;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.s_1 img:hover {
  opacity: 0.8;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #eaecf4;
  border-radius: 0.35rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4e73df;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.35rem;
}

.list-group-item-action {
  width: 100%;
  color: #6e707e;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #6e707e;
  text-decoration: none;
  background-color: #f8f9fc;
}

.list-group-item-action:active {
  color: #858796;
  background-color: #eaecf4;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #858796;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4e73df;
  border-color: #4e73df;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.35rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.35rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #293c74;
  background-color: #cdd8f6;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #293c74;
  background-color: #b7c7f2;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #293c74;
  border-color: #293c74;
}

.list-group-item-secondary {
  color: #45464e;
  background-color: #dddde2;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #45464e;
  background-color: #cfcfd6;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #45464e;
  border-color: #45464e;
}

.list-group-item-success {
  color: #0f6848;
  background-color: #bff0de;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #0f6848;
  background-color: #aaebd3;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f6848;
  border-color: #0f6848;
}

.list-group-item-info {
  color: #1c606a;
  background-color: #c7ebf1;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #1c606a;
  background-color: #b3e4ec;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #1c606a;
  border-color: #1c606a;
}

.list-group-item-warning {
  color: #806520;
  background-color: #fceec9;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #806520;
  background-color: #fbe6b1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #806520;
  border-color: #806520;
}

.list-group-item-danger {
  color: #78261f;
  background-color: #f8ccc8;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #78261f;
  background-color: #f5b7b1;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #78261f;
  border-color: #78261f;
}

.list-group-item-light {
  color: #818183;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818183;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818183;
  border-color: #818183;
}

.list-group-item-dark {
  color: #2f3037;
  background-color: #d1d1d5;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #2f3037;
  background-color: #c4c4c9;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2f3037;
  border-color: #2f3037;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #858796;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e3e6f0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e3e6f0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.35rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^="top"] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="top"] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^="right"] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^="left"] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="left"] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #858796;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #4e73df !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #2653d4 !important;
}

.bg-secondary {
  background-color: #858796 !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #6b6d7d !important;
}

.bg-success {
  background-color: #1cc88a !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #169b6b !important;
}

.bg-info {
  background-color: #36b9cc !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #2a96a5 !important;
}

.bg-warning {
  background-color: #f6c23e !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #f4b30d !important;
}

.bg-danger {
  background-color: #e74a3b !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #d52a1a !important;
}

.bg-light {
  background-color: #f8f9fc !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #d4daed !important;
}

.bg-dark {
  background-color: #5a5c69 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #42444e !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e3e6f0 !important;
}

.border-top {
  border-top: 1px solid #e3e6f0 !important;
}

.border-right {
  border-right: 1px solid #e3e6f0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e3e6f0 !important;
}

.border-left {
  border-left: 1px solid #e3e6f0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #4e73df !important;
}

.border-secondary {
  border-color: #858796 !important;
}

.border-success {
  border-color: #1cc88a !important;
}

.border-info {
  border-color: #36b9cc !important;
}

.border-warning {
  border-color: #f6c23e !important;
}

.border-danger {
  border-color: #e74a3b !important;
}

.border-light {
  border-color: #f8f9fc !important;
}

.border-dark {
  border-color: #5a5c69 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.35rem !important;
}

.rounded-top {
  border-top-left-radius: 0.35rem !important;
  border-top-right-radius: 0.35rem !important;
}

.rounded-right {
  border-top-right-radius: 0.35rem !important;
  border-bottom-right-radius: 0.35rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.35rem !important;
  border-bottom-left-radius: 0.35rem !important;
}

.rounded-left {
  border-top-left-radius: 0.35rem !important;
  border-bottom-left-radius: 0.35rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.dropdown .dropdown-menu .dropdown-header,
.sidebar .sidebar-heading,
.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #4e73df !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #224abe !important;
}

.text-secondary {
  color: #858796 !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #60616f !important;
}

.text-success {
  color: #1cc88a !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #13855c !important;
}

.text-info {
  color: #36b9cc !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #258391 !important;
}

.text-warning {
  color: #f6c23e !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #dda20a !important;
}

.text-danger {
  color: #e74a3b !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #be2617 !important;
}

.text-light {
  color: #f8f9fc !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #c2cbe5 !important;
}

.text-dark {
  color: #5a5c69 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #373840 !important;
}

.text-body {
  color: #858796 !important;
}

.text-muted {
  color: #858796 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #b7b9cc;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dddfeb !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #e3e6f0;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #e3e6f0;
  }
}

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
}

a:focus {
  outline: 0;
}

#wrapper {
  display: flex;
}

#wrapper #content-wrapper {
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
}

#wrapper #content-wrapper #content {
  flex: 1 0 auto;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background: rgba(90, 92, 105, 0.5);
  line-height: 46px;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
  color: #fff;
}

.scroll-to-top:hover {
  background: #5a5c69;
}

.scroll-to-top i {
  font-weight: 800;
}

@-webkit-keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animated--grow-in,
.sidebar .nav-item .collapse {
  -webkit-animation-name: growIn;
  animation-name: growIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animated--fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}

.bg-gradient-primary {
  background-color: #4e73df;
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover;
}

.bg-gradient-secondary {
  background-color: #858796;
  background-image: linear-gradient(180deg, #858796 10%, #60616f 100%);
  background-size: cover;
}

.bg-gradient-success {
  background-color: #1cc88a;
  background-image: linear-gradient(180deg, #1cc88a 10%, #13855c 100%);
  background-size: cover;
}

.bg-gradient-info {
  background-color: #36b9cc;
  background-image: linear-gradient(180deg, #36b9cc 10%, #258391 100%);
  background-size: cover;
}

.bg-gradient-warning {
  background-color: #f6c23e;
  background-image: linear-gradient(180deg, #f6c23e 10%, #dda20a 100%);
  background-size: cover;
}

.bg-gradient-danger {
  background-color: #e74a3b;
  background-image: linear-gradient(180deg, #e74a3b 10%, #be2617 100%);
  background-size: cover;
}

.bg-gradient-light {
  background-color: #f8f9fc;
  background-image: linear-gradient(180deg, #f8f9fc 10%, #c2cbe5 100%);
  background-size: cover;
}

.bg-gradient-dark {
  background-color: #5a5c69;
  background-image: linear-gradient(180deg, #5a5c69 10%, #373840 100%);
  background-size: cover;
}

.bg-gray-100 {
  background-color: #f8f9fc !important;
}

.bg-gray-200 {
  background-color: #eaecf4 !important;
}

.bg-gray-300 {
  background-color: #dddfeb !important;
}

.bg-gray-400 {
  background-color: #d1d3e2 !important;
}

.bg-gray-500 {
  background-color: #b7b9cc !important;
}

.bg-gray-600 {
  background-color: #858796 !important;
}

.bg-gray-700 {
  background-color: #6e707e !important;
}

.bg-gray-800 {
  background-color: #5a5c69 !important;
}

.bg-gray-900 {
  background-color: #3a3b45 !important;
}

.o-hidden {
  overflow: hidden !important;
}

.text-xs {
  font-size: 0.7rem;
}

.text-lg {
  font-size: 1.2rem;
}

.text-gray-100 {
  color: #f8f9fc !important;
}

.text-gray-200 {
  color: #eaecf4 !important;
}

.text-gray-300 {
  color: #dddfeb !important;
}

.text-gray-400 {
  color: #d1d3e2 !important;
}

.text-gray-500 {
  color: #b7b9cc !important;
}

.text-gray-600 {
  color: #858796 !important;
}

.text-gray-700 {
  color: #6e707e !important;
}

.text-gray-800 {
  color: #5a5c69 !important;
}

.text-gray-900 {
  color: #3a3b45 !important;
}

.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}

.border-bottom-primary {
  border-bottom: 0.25rem solid #4e73df !important;
}

.border-left-secondary {
  border-left: 0.25rem solid #858796 !important;
}

.border-bottom-secondary {
  border-bottom: 0.25rem solid #858796 !important;
}

.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}

.border-bottom-success {
  border-bottom: 0.25rem solid #1cc88a !important;
}

.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}

.border-bottom-info {
  border-bottom: 0.25rem solid #36b9cc !important;
}

.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}

.border-bottom-warning {
  border-bottom: 0.25rem solid #f6c23e !important;
}

.border-left-danger {
  border-left: 0.25rem solid #e74a3b !important;
}

.border-bottom-danger {
  border-bottom: 0.25rem solid #e74a3b !important;
}

.border-left-light {
  border-left: 0.25rem solid #f8f9fc !important;
}

.border-bottom-light {
  border-bottom: 0.25rem solid #f8f9fc !important;
}

.border-left-dark {
  border-left: 0.25rem solid #5a5c69 !important;
}

.border-bottom-dark {
  border-bottom: 0.25rem solid #5a5c69 !important;
}

.progress-sm {
  height: 0.5rem;
}

.rotate-15 {
  transform: rotate(15deg);
}

.rotate-n-15 {
  transform: rotate(-15deg);
}

.dropdown .dropdown-menu {
  font-size: 0.85rem;
}

.dropdown .dropdown-menu .dropdown-header {
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}

.dropdown.no-arrow .dropdown-toggle::after {
  display: none;
}

.sidebar .nav-item.dropdown .dropdown-toggle::after,
.topbar .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
}

.sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.topbar .nav-item.dropdown.show .dropdown-toggle::after {
  content: "\f107";
}

.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
  position: relative;
}

.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: 0.25rem;
  margin-top: -0.25rem;
}

.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
  height: 2rem;
  width: 2rem;
}

.topbar {
  height: 4.375rem;
}

.topbar #sidebarToggleTop {
  height: 2.5rem;
  width: 2.5rem;
}

.topbar #sidebarToggleTop:hover {
  background-color: #eaecf4;
}

.topbar #sidebarToggleTop:active {
  background-color: #dddfeb;
}

.topbar .navbar-search {
  width: 25rem;
}

.topbar .navbar-search input {
  font-size: 0.85rem;
  height: auto;
}

.topbar .topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}

.topbar .nav-item .nav-link {
  height: 4.375rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}

.topbar .nav-item .nav-link:focus {
  outline: 0;
}

.topbar .nav-item:focus {
  outline: 0;
}

.topbar .dropdown {
  position: static;
}

.topbar .dropdown .dropdown-menu {
  width: calc(100% - 1.5rem);
  right: 0.75rem;
}

.topbar .dropdown-list {
  padding: 0;
  border: none;
  overflow: hidden;
}

.topbar .dropdown-list .dropdown-header {
  background-color: #4e73df;
  border: 1px solid #4e73df;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
}

.topbar .dropdown-list .dropdown-item {
  white-space: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-left: 1px solid #e3e6f0;
  border-right: 1px solid #e3e6f0;
  border-bottom: 1px solid #e3e6f0;
  line-height: 1.3rem;
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image img {
  height: 2.5rem;
  width: 2.5rem;
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
  background-color: #eaecf4;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0.125rem solid #fff;
}

.topbar .dropdown-list .dropdown-item .text-truncate {
  max-width: 10rem;
}

.topbar .dropdown-list .dropdown-item:active {
  background-color: #eaecf4;
  color: #3a3b45;
}

@media (min-width: 576px) {
  .topbar .dropdown {
    position: relative;
  }

  .topbar .dropdown .dropdown-menu {
    width: auto;
    right: 0;
  }

  .topbar .dropdown-list {
    width: 20rem !important;
  }

  .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 13.375rem;
  }
}

.topbar.navbar-dark .navbar-nav .nav-item .nav-link:active {
  color: #fff;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #d1d3e2;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #b7b9cc;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
  color: #858796;
}

.sidebar {
  width: 6.5rem;
  min-height: 100vh;
}

.sidebar .nav-item {
  position: relative;
}

.sidebar .nav-item:last-child {
  margin-bottom: 1rem;
}

.sidebar .nav-item .nav-link {
  text-align: center;
  padding: 0.75rem 1rem;
  width: 6.5rem;
}

.sidebar .nav-item .nav-link span {
  font-size: 0.65rem;
  display: block;
}

.sidebar .nav-item.active .nav-link {
  font-weight: 700;
}

.sidebar .nav-item .collapse {
  position: absolute;
  left: calc(6.5rem + 1.5rem / 2);
  z-index: 1;
  top: 2px;
}

.sidebar .nav-item .collapse .collapse-inner {
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.sidebar .nav-item .collapsing {
  display: none;
  transition: none;
}

.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
  padding: 0.5rem 0;
  min-width: 10rem;
  font-size: 0.85rem;
  margin: 0 0 1rem 0;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-header,
.sidebar .nav-item .collapsing .collapse-inner .collapse-header {
  margin: 0;
  white-space: nowrap;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  display: block;
  color: #3a3b45;
  text-decoration: none;
  border-radius: 0.35rem;
  white-space: nowrap;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
  background-color: #eaecf4;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
  background-color: #dddfeb;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: #4e73df;
  font-weight: 700;
}

.sidebar #sidebarToggle {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.sidebar #sidebarToggle::after {
  font-weight: 900;
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  margin-right: 0.1rem;
}

.sidebar #sidebarToggle:hover {
  text-decoration: none;
}

.sidebar #sidebarToggle:focus {
  outline: 0;
}

.sidebar.toggled {
  width: 0 !important;
  overflow: hidden;
}

.sidebar.toggled #sidebarToggle::after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  margin-left: 0.25rem;
}

.sidebar.toggled .sidebar-card {
  display: none;
}

.sidebar .sidebar-brand {
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
}

.sidebar .sidebar-brand .sidebar-brand-icon i {
  font-size: 2rem;
}

.sidebar .sidebar-brand .sidebar-brand-text {
  display: none;
}

.sidebar hr.sidebar-divider {
  margin: 0 1rem 1rem;
}

.sidebar .sidebar-heading {
  text-align: center;
  padding: 0 1rem;
  font-weight: 800;
  font-size: 0.65rem;
}

.sidebar .sidebar-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 0.35rem;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .sidebar-card .sidebar-card-illustration {
  height: 3rem;
  display: block;
}

.sidebar .sidebar-card .sidebar-card-title {
  font-weight: 700;
}

.sidebar .sidebar-card p {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
  .sidebar {
    width: 14rem !important;
  }

  .sidebar .nav-item .collapse {
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    -webkit-animation: none;
    animation: none;
  }

  .sidebar .nav-item .collapse .collapse-inner {
    border-radius: 0;
    box-shadow: none;
  }

  .sidebar .nav-item .collapsing {
    display: block;
    transition: height 0.15s ease;
  }

  .sidebar .nav-item .collapse,
  .sidebar .nav-item .collapsing {
    margin: 0 1rem;
  }

  .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem;
    width: 14rem;
  }

  .sidebar .nav-item .nav-link i {
    font-size: 0.85rem;
    margin-right: 0.25rem;
  }

  .sidebar .nav-item .nav-link span {
    font-size: 0.85rem;
    display: inline;
  }

  .sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
  }

  .sidebar .nav-item .nav-link[data-toggle="collapse"].collapsed::after {
    content: "\f105";
  }

  .sidebar .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
  }

  .sidebar .sidebar-brand .sidebar-brand-text {
    display: inline;
  }

  .sidebar .sidebar-heading {
    text-align: left;
  }

  .sidebar.toggled {
    overflow: visible;
    width: 6.5rem !important;
  }

  .sidebar.toggled .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 2px;
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-timing-function: transform
        cubic-bezier(0.18, 1.25, 0.4, 1),
      opacity cubic-bezier(0, 1, 0.4, 1);
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
      opacity cubic-bezier(0, 1, 0.4, 1);
  }

  .sidebar.toggled .nav-item .collapse .collapse-inner {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 0.35rem;
  }

  .sidebar.toggled .nav-item .collapsing {
    display: none;
    transition: none;
  }

  .sidebar.toggled .nav-item .collapse,
  .sidebar.toggled .nav-item .collapsing {
    margin: 0;
  }

  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
  }

  .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 6.5rem;
  }

  .sidebar.toggled .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }

  .sidebar.toggled .nav-item .nav-link i {
    margin-right: 0;
  }

  .sidebar.toggled .nav-item .nav-link[data-toggle="collapse"]::after {
    display: none;
  }

  .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
  }

  .sidebar.toggled .sidebar-brand .sidebar-brand-text {
    display: none;
  }

  .sidebar.toggled .sidebar-heading {
    text-align: center;
  }
}

.sidebar-light .sidebar-brand {
  color: #6e707e;
}

.sidebar-light hr.sidebar-divider {
  border-top: 1px solid #eaecf4;
}

.sidebar-light .sidebar-heading {
  color: #b7b9cc;
}

.sidebar-light .nav-item .nav-link {
  color: #858796;
}

.sidebar-light .nav-item .nav-link i {
  color: #d1d3e2;
}

.sidebar-light .nav-item .nav-link:active,
.sidebar-light .nav-item .nav-link:focus,
.sidebar-light .nav-item .nav-link:hover {
  color: #6e707e;
}

.sidebar-light .nav-item .nav-link:active i,
.sidebar-light .nav-item .nav-link:focus i,
.sidebar-light .nav-item .nav-link:hover i {
  color: #6e707e;
}

.sidebar-light .nav-item .nav-link[data-toggle="collapse"]::after {
  color: #b7b9cc;
}

.sidebar-light .nav-item.active .nav-link {
  color: #6e707e;
}

.sidebar-light .nav-item.active .nav-link i {
  color: #6e707e;
}

.sidebar-light #sidebarToggle {
  background-color: #eaecf4;
}

.sidebar-light #sidebarToggle::after {
  color: #b7b9cc;
}

.sidebar-light #sidebarToggle:hover {
  background-color: #dddfeb;
}

.sidebar-dark .sidebar-brand {
  color: #fff;
}

.sidebar-dark hr.sidebar-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.sidebar-dark .sidebar-heading {
  color: rgba(255, 255, 255, 0.4);
}

.sidebar-dark .nav-item .nav-link i {
  color: rgba(255, 255, 255, 0.3);
}

.sidebar-dark .nav-item .nav-link:active,
.sidebar-dark .nav-item .nav-link:focus,
.sidebar-dark .nav-item .nav-link:hover {
  color: #fff;
}

.sidebar-dark .nav-item .nav-link:active i,
.sidebar-dark .nav-item .nav-link:focus i,
.sidebar-dark .nav-item .nav-link:hover i {
  color: #fff;
}

.sidebar-dark .nav-item .nav-link[data-toggle="collapse"]::after {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark #sidebarToggle {
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-dark #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark #sidebarToggle:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.sidebar-dark.toggled #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}

.btn-circle {
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  height: 1.8rem;
  width: 1.8rem;
  font-size: 0.75rem;
}

.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  height: 3.5rem;
  width: 3.5rem;
  font-size: 1.35rem;
}

.btn-icon-split {
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
}

.btn-icon-split .icon {
  background: rgba(0, 0, 0, 0.15);
  display: inline-block;
  padding: 0.375rem 0.75rem;
}

.btn-icon-split .text {
  display: inline-block;
  padding: 0.375rem 0.75rem;
}

.btn-group-sm > .btn-icon-split.btn .icon,
.btn-icon-split.btn-sm .icon {
  padding: 0.25rem 0.5rem;
}

.btn-group-sm > .btn-icon-split.btn .text,
.btn-icon-split.btn-sm .text {
  padding: 0.25rem 0.5rem;
}

.btn-group-lg > .btn-icon-split.btn .icon,
.btn-icon-split.btn-lg .icon {
  padding: 0.5rem 1rem;
}

.btn-group-lg > .btn-icon-split.btn .text,
.btn-icon-split.btn-lg .text {
  padding: 0.5rem 1rem;
}

.card .card-header .dropdown {
  line-height: 1;
}

.card .card-header .dropdown .dropdown-menu {
  line-height: 1.5;
}

.card .card-header[data-toggle="collapse"] {
  text-decoration: none;
  position: relative;
  padding: 0.75rem 3.25rem 0.75rem 1.25rem;
}

.card .card-header[data-toggle="collapse"]::after {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.725rem;
  line-height: 51px;
  font-weight: 900;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  color: #d1d3e2;
}

.card .card-header[data-toggle="collapse"].collapsed {
  border-radius: 0.35rem;
}

.card .card-header[data-toggle="collapse"].collapsed::after {
  content: "\f105";
}

.chart-area {
  position: relative;
  height: 10rem;
  width: 100%;
}

@media (min-width: 768px) {
  .chart-area {
    height: 20rem;
  }
}

.chart-bar {
  position: relative;
  height: 10rem;
  width: 100%;
}

@media (min-width: 768px) {
  .chart-bar {
    height: 20rem;
  }
}

.chart-pie {
  position: relative;
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .chart-pie {
    height: calc(20rem - 43px) !important;
  }
}

.bg-login-image {
  background: url(https://source.unsplash.com/K4mSJ7kc0As/600x800);
  background-position: center;
  background-size: cover;
}

.bg-register-image {
  background: url(https://source.unsplash.com/Mv9hjnEUHR4/600x800);
  background-position: center;
  background-size: cover;
}

.bg-password-image {
  background: url(https://source.unsplash.com/oWTW-jNGl9I/600x800);
  background-position: center;
  background-size: cover;
}

form.user .custom-checkbox.small label {
  line-height: 1.5rem;
}

form.user .form-control-user {
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 1.5rem 1rem;
}

form.user .btn-user {
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 0.75rem 1rem;
}

.btn-google {
  color: #fff;
  background-color: #ea4335;
  border-color: #fff;
}

.btn-google:hover {
  color: #fff;
  background-color: #e12717;
  border-color: #e6e6e6;
}

.btn-google.focus,
.btn-google:focus {
  color: #fff;
  background-color: #e12717;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-google.disabled,
.btn-google:disabled {
  color: #fff;
  background-color: #ea4335;
  border-color: #fff;
}

.btn-google:not(:disabled):not(.disabled).active,
.btn-google:not(:disabled):not(.disabled):active,
.show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #d62516;
  border-color: #dfdfdf;
}

.btn-google:not(:disabled):not(.disabled).active:focus,
.btn-google:not(:disabled):not(.disabled):active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #fff;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #e6e6e6;
}

.btn-facebook.focus,
.btn-facebook:focus {
  color: #fff;
  background-color: #30497c;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #fff;
}

.btn-facebook:not(:disabled):not(.disabled).active,
.btn-facebook:not(:disabled):not(.disabled):active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #dfdfdf;
}

.btn-facebook:not(:disabled):not(.disabled).active:focus,
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.error {
  color: #5a5c69;
  font-size: 7rem;
  position: relative;
  line-height: 1;
  width: 12.5rem;
}

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}

.error:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #e74a3b;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

@-webkit-keyframes noise-anim-2 {
  0% {
    clip: rect(16px, 9999px, 10px, 0);
  }

  5% {
    clip: rect(22px, 9999px, 29px, 0);
  }

  10% {
    clip: rect(6px, 9999px, 68px, 0);
  }

  15% {
    clip: rect(85px, 9999px, 95px, 0);
  }

  20% {
    clip: rect(65px, 9999px, 91px, 0);
  }

  25% {
    clip: rect(93px, 9999px, 68px, 0);
  }

  30% {
    clip: rect(10px, 9999px, 27px, 0);
  }

  35% {
    clip: rect(37px, 9999px, 25px, 0);
  }

  40% {
    clip: rect(12px, 9999px, 23px, 0);
  }

  45% {
    clip: rect(40px, 9999px, 18px, 0);
  }

  50% {
    clip: rect(19px, 9999px, 71px, 0);
  }

  55% {
    clip: rect(2px, 9999px, 35px, 0);
  }

  60% {
    clip: rect(16px, 9999px, 69px, 0);
  }

  65% {
    clip: rect(8px, 9999px, 65px, 0);
  }

  70% {
    clip: rect(30px, 9999px, 57px, 0);
  }

  75% {
    clip: rect(14px, 9999px, 4px, 0);
  }

  80% {
    clip: rect(39px, 9999px, 30px, 0);
  }

  85% {
    clip: rect(22px, 9999px, 35px, 0);
  }

  90% {
    clip: rect(58px, 9999px, 71px, 0);
  }

  95% {
    clip: rect(34px, 9999px, 90px, 0);
  }

  100% {
    clip: rect(67px, 9999px, 68px, 0);
  }
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(16px, 9999px, 10px, 0);
  }

  5% {
    clip: rect(22px, 9999px, 29px, 0);
  }

  10% {
    clip: rect(6px, 9999px, 68px, 0);
  }

  15% {
    clip: rect(85px, 9999px, 95px, 0);
  }

  20% {
    clip: rect(65px, 9999px, 91px, 0);
  }

  25% {
    clip: rect(93px, 9999px, 68px, 0);
  }

  30% {
    clip: rect(10px, 9999px, 27px, 0);
  }

  35% {
    clip: rect(37px, 9999px, 25px, 0);
  }

  40% {
    clip: rect(12px, 9999px, 23px, 0);
  }

  45% {
    clip: rect(40px, 9999px, 18px, 0);
  }

  50% {
    clip: rect(19px, 9999px, 71px, 0);
  }

  55% {
    clip: rect(2px, 9999px, 35px, 0);
  }

  60% {
    clip: rect(16px, 9999px, 69px, 0);
  }

  65% {
    clip: rect(8px, 9999px, 65px, 0);
  }

  70% {
    clip: rect(30px, 9999px, 57px, 0);
  }

  75% {
    clip: rect(14px, 9999px, 4px, 0);
  }

  80% {
    clip: rect(39px, 9999px, 30px, 0);
  }

  85% {
    clip: rect(22px, 9999px, 35px, 0);
  }

  90% {
    clip: rect(58px, 9999px, 71px, 0);
  }

  95% {
    clip: rect(34px, 9999px, 90px, 0);
  }

  100% {
    clip: rect(67px, 9999px, 68px, 0);
  }
}

.error:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}

footer.sticky-footer {
  padding: 2rem 0;
  flex-shrink: 0;
}

footer.sticky-footer .copyright {
  line-height: 1;
  font-size: 0.8rem;
}

body.sidebar-toggled footer.sticky-footer {
  width: 100%;
}

.nav-item.active span {
  color: #000 !important;
}

.nav-link span {
  color: #fff !important;
}

.active {
  background: #fff !important;
  color: #000 !important;
}

.pricing-3-list {
  grid-template-columns: auto auto;
}

@media only screen and (max-width: 991px) {
  .pricing-3-list {
    grid-template-columns: auto;
  }
}

#gallery-2 .img-fluid{
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

.gallery-image{
  height: 100% !important;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  #pricing-3 .detail-price h5 {
    font-size: 22px !important;
  }
}